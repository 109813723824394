/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ActiveHaulsFilter = {
    Date: 'Date',
    GearGroup: 'GearGroup',
    SpeciesGroup: 'SpeciesGroup',
    VesselLength: 'VesselLength'
} as const;

export type ActiveHaulsFilter = typeof ActiveHaulsFilter[keyof typeof ActiveHaulsFilter];


/**
 * 
 * @export
 * @enum {string}
 */

export const ActiveLandingFilter = {
    Date: 'Date',
    GearGroup: 'GearGroup',
    SpeciesGroup: 'SpeciesGroup',
    VesselLength: 'VesselLength'
} as const;

export type ActiveLandingFilter = typeof ActiveLandingFilter[keyof typeof ActiveLandingFilter];


/**
 * 
 * @export
 * @interface AisCurrentPositionParameters
 */
export interface AisCurrentPositionParameters {
    /**
     * 
     * @type {string}
     * @memberof AisCurrentPositionParameters
     */
    'positionTimestampLimit': string | null;
}
/**
 * 
 * @export
 * @interface AisPosition
 */
export interface AisPosition {
    /**
     * 
     * @type {number}
     * @memberof AisPosition
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof AisPosition
     */
    'lon': number;
    /**
     * 
     * @type {string}
     * @memberof AisPosition
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof AisPosition
     */
    'cog': number | null;
    /**
     * 
     * @type {AisPositionDetails}
     * @memberof AisPosition
     */
    'det': AisPositionDetails | null;
}
/**
 * 
 * @export
 * @interface AisPositionDetails
 */
export interface AisPositionDetails {
    /**
     * 
     * @type {NavigationStatus}
     * @memberof AisPositionDetails
     */
    'navigationalStatus': NavigationStatus | null;
    /**
     * 
     * @type {number}
     * @memberof AisPositionDetails
     */
    'rateOfTurn': number | null;
    /**
     * 
     * @type {number}
     * @memberof AisPositionDetails
     */
    'speedOverGround': number | null;
    /**
     * 
     * @type {number}
     * @memberof AisPositionDetails
     */
    'trueHeading': number | null;
    /**
     * 
     * @type {number}
     * @memberof AisPositionDetails
     */
    'distanceToShore': number;
    /**
     * 
     * @type {boolean}
     * @memberof AisPositionDetails
     */
    'missingData': boolean;
}


/**
 * 
 * @export
 * @interface AisTrackParameters
 */
export interface AisTrackParameters {
    /**
     * 
     * @type {string}
     * @memberof AisTrackParameters
     */
    'start': string | null;
    /**
     * 
     * @type {string}
     * @memberof AisTrackParameters
     */
    'end': string | null;
}
/**
 * 
 * @export
 * @interface AisTrackPath
 */
export interface AisTrackPath {
    /**
     * 
     * @type {number}
     * @memberof AisTrackPath
     */
    'mmsi': number;
}
/**
 * 
 * @export
 * @interface AisVessel
 */
export interface AisVessel {
    /**
     * 
     * @type {number}
     * @memberof AisVessel
     */
    'mmsi': number;
    /**
     * 
     * @type {number}
     * @memberof AisVessel
     */
    'imoNumber': number | null;
    /**
     * 
     * @type {string}
     * @memberof AisVessel
     */
    'callSign': string | null;
    /**
     * 
     * @type {string}
     * @memberof AisVessel
     */
    'name': string | null;
    /**
     * 
     * @type {number}
     * @memberof AisVessel
     */
    'shipLength': number | null;
    /**
     * 
     * @type {number}
     * @memberof AisVessel
     */
    'shipWidth': number | null;
    /**
     * 
     * @type {string}
     * @memberof AisVessel
     */
    'eta': string | null;
    /**
     * 
     * @type {string}
     * @memberof AisVessel
     */
    'destination': string | null;
}
/**
 * 
 * @export
 * @interface AisVmsArea
 */
export interface AisVmsArea {
    /**
     * 
     * @type {number}
     * @memberof AisVmsArea
     */
    'numVessels': number;
    /**
     * 
     * @type {Array<AisVmsAreaCount>}
     * @memberof AisVmsArea
     */
    'counts': Array<AisVmsAreaCount>;
}
/**
 * 
 * @export
 * @interface AisVmsAreaCount
 */
export interface AisVmsAreaCount {
    /**
     * 
     * @type {number}
     * @memberof AisVmsAreaCount
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof AisVmsAreaCount
     */
    'lon': number;
    /**
     * 
     * @type {number}
     * @memberof AisVmsAreaCount
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AisVmsAreaParameters
 */
export interface AisVmsAreaParameters {
    /**
     * 
     * @type {number}
     * @memberof AisVmsAreaParameters
     */
    'x1': number;
    /**
     * 
     * @type {number}
     * @memberof AisVmsAreaParameters
     */
    'x2': number;
    /**
     * 
     * @type {number}
     * @memberof AisVmsAreaParameters
     */
    'y1': number;
    /**
     * 
     * @type {number}
     * @memberof AisVmsAreaParameters
     */
    'y2': number;
    /**
     * 
     * @type {string}
     * @memberof AisVmsAreaParameters
     */
    'dateLimit': string | null;
}
/**
 * 
 * @export
 * @interface AisVmsParameters
 */
export interface AisVmsParameters {
    /**
     * 
     * @type {number}
     * @memberof AisVmsParameters
     */
    'mmsi': number | null;
    /**
     * 
     * @type {string}
     * @memberof AisVmsParameters
     */
    'callSign': string | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsParameters
     */
    'tripId': number | null;
    /**
     * 
     * @type {string}
     * @memberof AisVmsParameters
     */
    'start': string | null;
    /**
     * 
     * @type {string}
     * @memberof AisVmsParameters
     */
    'end': string | null;
}
/**
 * 
 * @export
 * @interface AisVmsPosition
 */
export interface AisVmsPosition {
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'lon': number;
    /**
     * 
     * @type {string}
     * @memberof AisVmsPosition
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'cog'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'speed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'tripCumulativeFuelConsumption'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPosition
     */
    'tripCumulativeCargoWeight'?: number | null;
    /**
     * 
     * @type {TripPositionLayerId}
     * @memberof AisVmsPosition
     */
    'prunedBy'?: TripPositionLayerId | null;
    /**
     * 
     * @type {AisVmsPositionDetails}
     * @memberof AisVmsPosition
     */
    'det'?: AisVmsPositionDetails | null;
}


/**
 * 
 * @export
 * @interface AisVmsPositionDetails
 */
export interface AisVmsPositionDetails {
    /**
     * 
     * @type {NavigationStatus}
     * @memberof AisVmsPositionDetails
     */
    'navigationalStatus'?: NavigationStatus | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPositionDetails
     */
    'rateOfTurn'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPositionDetails
     */
    'trueHeading'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AisVmsPositionDetails
     */
    'distanceToShore': number;
    /**
     * 
     * @type {boolean}
     * @memberof AisVmsPositionDetails
     */
    'missingData': boolean;
}


/**
 * 
 * @export
 * @interface AllFishingPredictionsPath
 */
export interface AllFishingPredictionsPath {
    /**
     * 
     * @type {ModelId}
     * @memberof AllFishingPredictionsPath
     */
    'model_id': ModelId;
}


/**
 * 
 * @export
 * @interface AverageEeoiParams
 */
export interface AverageEeoiParams {
    /**
     * 
     * @type {string}
     * @memberof AverageEeoiParams
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AverageEeoiParams
     */
    'endDate': string;
    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof AverageEeoiParams
     */
    'gearGroups[]': Array<GearGroup> | null;
    /**
     * 
     * @type {VesselLengthGroup}
     * @memberof AverageEeoiParams
     */
    'lengthGroup': VesselLengthGroup | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AverageEeoiParams
     */
    'vesselIds[]': Array<number> | null;
}


/**
 * 
 * @export
 * @interface AverageTripBenchmarks
 */
export interface AverageTripBenchmarks {
    /**
     * 
     * @type {number}
     * @memberof AverageTripBenchmarks
     */
    'weightPerHour': number | null;
    /**
     * 
     * @type {number}
     * @memberof AverageTripBenchmarks
     */
    'weightPerDistance': number | null;
    /**
     * 
     * @type {number}
     * @memberof AverageTripBenchmarks
     */
    'weightPerFuel': number | null;
    /**
     * 
     * @type {number}
     * @memberof AverageTripBenchmarks
     */
    'catchValuePerFuel': number | null;
    /**
     * 
     * @type {number}
     * @memberof AverageTripBenchmarks
     */
    'fuelConsumption': number | null;
}
/**
 * 
 * @export
 * @interface AverageTripBenchmarksParams
 */
export interface AverageTripBenchmarksParams {
    /**
     * 
     * @type {string}
     * @memberof AverageTripBenchmarksParams
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AverageTripBenchmarksParams
     */
    'endDate': string;
    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof AverageTripBenchmarksParams
     */
    'gearGroups[]': Array<GearGroup> | null;
    /**
     * 
     * @type {VesselLengthGroup}
     * @memberof AverageTripBenchmarksParams
     */
    'lengthGroup': VesselLengthGroup | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AverageTripBenchmarksParams
     */
    'vesselIds[]': Array<number> | null;
}


/**
 * 
 * @export
 * @interface Benchmark
 */
export interface Benchmark {
    /**
     * 
     * @type {number}
     * @memberof Benchmark
     */
    'average': number;
    /**
     * 
     * @type {number}
     * @memberof Benchmark
     */
    'averageFollowers': number;
    /**
     * 
     * @type {Array<BenchmarkEntry>}
     * @memberof Benchmark
     */
    'recentTrips': Array<BenchmarkEntry>;
}
/**
 * 
 * @export
 * @interface BenchmarkEntry
 */
export interface BenchmarkEntry {
    /**
     * 
     * @type {number}
     * @memberof BenchmarkEntry
     */
    'fiskeridirVesselId': number;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkEntry
     */
    'tripStart': string;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkEntry
     */
    'value': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BuyerLocationType = {
    LegalEntity: 'LegalEntity',
    DockSalesperson: 'DockSalesperson',
    Trader: 'Trader',
    Vessel: 'Vessel',
    Vehicle: 'Vehicle',
    OrdinaryFacility: 'OrdinaryFacility',
    NetPen: 'NetPen'
} as const;

export type BuyerLocationType = typeof BuyerLocationType[keyof typeof BuyerLocationType];


/**
 * 
 * @export
 * @interface Catch
 */
export interface Catch {
    /**
     * 
     * @type {number}
     * @memberof Catch
     */
    'livingWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Catch
     */
    'grossWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Catch
     */
    'productWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Catch
     */
    'speciesFiskeridirId': number;
    /**
     * 
     * @type {Quality}
     * @memberof Catch
     */
    'productQualityId': Quality;
    /**
     * 
     * @type {string}
     * @memberof Catch
     */
    'productQualityName': string;
    /**
     * 
     * @type {number}
     * @memberof Catch
     */
    'priceForFisher': number;
}


/**
 * 
 * @export
 * @interface CumulativeLandings
 */
export interface CumulativeLandings {
    /**
     * 
     * @type {Month}
     * @memberof CumulativeLandings
     */
    'month': Month;
    /**
     * 
     * @type {number}
     * @memberof CumulativeLandings
     */
    'speciesFiskeridirId': number;
    /**
     * 
     * @type {number}
     * @memberof CumulativeLandings
     */
    'weight': number;
    /**
     * 
     * @type {number}
     * @memberof CumulativeLandings
     */
    'cumulativeWeight': number;
}


/**
 * 
 * @export
 * @interface CurrentAisPosition
 */
export interface CurrentAisPosition {
    /**
     * 
     * @type {number}
     * @memberof CurrentAisPosition
     */
    'mmsi': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentAisPosition
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof CurrentAisPosition
     */
    'lon': number;
    /**
     * 
     * @type {string}
     * @memberof CurrentAisPosition
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof CurrentAisPosition
     */
    'cog': number | null;
    /**
     * 
     * @type {NavigationStatus}
     * @memberof CurrentAisPosition
     */
    'navigationalStatus': NavigationStatus | null;
    /**
     * 
     * @type {number}
     * @memberof CurrentAisPosition
     */
    'rateOfTurn': number | null;
    /**
     * 
     * @type {number}
     * @memberof CurrentAisPosition
     */
    'speedOverGround': number | null;
    /**
     * 
     * @type {number}
     * @memberof CurrentAisPosition
     */
    'trueHeading': number | null;
    /**
     * 
     * @type {number}
     * @memberof CurrentAisPosition
     */
    'distanceToShore': number;
}


/**
 * 
 * @export
 * @interface CurrentTrip
 */
export interface CurrentTrip {
    /**
     * 
     * @type {string}
     * @memberof CurrentTrip
     */
    'departure': string;
    /**
     * 
     * @type {number}
     * @memberof CurrentTrip
     */
    'targetSpeciesFiskeridirId': number | null;
    /**
     * 
     * @type {Array<Haul>}
     * @memberof CurrentTrip
     */
    'hauls': Array<Haul>;
    /**
     * 
     * @type {Array<FishingFacility>}
     * @memberof CurrentTrip
     */
    'fishingFacilities': Array<FishingFacility>;
}
/**
 * 
 * @export
 * @interface CurrentTripPath
 */
export interface CurrentTripPath {
    /**
     * 
     * @type {number}
     * @memberof CurrentTripPath
     */
    'fiskeridir_vessel_id': number;
}
/**
 * 
 * @export
 * @interface DeleteFuelMeasurement
 */
export interface DeleteFuelMeasurement {
    /**
     * 
     * @type {string}
     * @memberof DeleteFuelMeasurement
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface Delivery
 */
export interface Delivery {
    /**
     * 
     * @type {Array<Catch>}
     * @memberof Delivery
     */
    'delivered': Array<Catch>;
    /**
     * 
     * @type {number}
     * @memberof Delivery
     */
    'totalLivingWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Delivery
     */
    'totalProductWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Delivery
     */
    'totalGrossWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Delivery
     */
    'totalPriceForFisher': number;
    /**
     * 
     * @type {boolean}
     * @memberof Delivery
     */
    'priceForFisherIsEstimated': boolean;
}
/**
 * 
 * @export
 * @interface DeliveryPoint
 */
export interface DeliveryPoint {
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliveryPoint
     */
    'address': string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPoint
     */
    'latitude': number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliveryPoint
     */
    'longitude': number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    ClosingSlip: 'ClosingSlip',
    LandingSlip: 'LandingSlip',
    LandingSlipInTransit: 'LandingSlipInTransit',
    DockSlip: 'DockSlip',
    LandingSlipFromFeedingVessel: 'LandingSlipFromFeedingVessel',
    CatchCertificate: 'CatchCertificate',
    EnrollmentDocument: 'EnrollmentDocument'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @interface EeoiParams
 */
export interface EeoiParams {
    /**
     * 
     * @type {string}
     * @memberof EeoiParams
     */
    'startDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof EeoiParams
     */
    'endDate': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ErrorDiscriminants = {
    StartAfterEnd: 'StartAfterEnd',
    InvalidCallSign: 'InvalidCallSign',
    MissingBwFiskInfoProfile: 'MissingBwFiskInfoProfile',
    InvalidDateRange: 'InvalidDateRange',
    MissingDateRange: 'MissingDateRange',
    MissingMmsiOrCallSignOrTripId: 'MissingMmsiOrCallSignOrTripId',
    InsufficientPermissions: 'InsufficientPermissions',
    MissingJwt: 'MissingJWT',
    InvalidJwt: 'InvalidJWT',
    ParseJwt: 'ParseJWT',
    JwtDecode: 'JWTDecode',
    QueryPayload: 'QueryPayload',
    UpdateVesselNotFound: 'UpdateVesselNotFound',
    OrgNotFound: 'OrgNotFound',
    Unexpected: 'Unexpected'
} as const;

export type ErrorDiscriminants = typeof ErrorDiscriminants[keyof typeof ErrorDiscriminants];


/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {ErrorDiscriminants}
     * @memberof ErrorResponse
     */
    'error': ErrorDiscriminants;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'description': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ErsQuantumType = {
    Kg: 'KG',
    Ob: 'OB'
} as const;

export type ErsQuantumType = typeof ErsQuantumType[keyof typeof ErsQuantumType];


/**
 * 
 * @export
 * @interface FishingFacilitiesParams
 */
export interface FishingFacilitiesParams {
    /**
     * 
     * @type {Array<number>}
     * @memberof FishingFacilitiesParams
     */
    'mmsis[]': Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FishingFacilitiesParams
     */
    'fiskeridirVesselIds[]': Array<number> | null;
    /**
     * 
     * @type {Array<FishingFacilityToolType>}
     * @memberof FishingFacilitiesParams
     */
    'toolTypes[]': Array<FishingFacilityToolType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FishingFacilitiesParams
     */
    'active': boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FishingFacilitiesParams
     */
    'setupRanges[]': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FishingFacilitiesParams
     */
    'removedRanges[]': Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof FishingFacilitiesParams
     */
    'limit': number | null;
    /**
     * 
     * @type {number}
     * @memberof FishingFacilitiesParams
     */
    'offset': number | null;
    /**
     * 
     * @type {Ordering}
     * @memberof FishingFacilitiesParams
     */
    'ordering': Ordering | null;
    /**
     * 
     * @type {FishingFacilitiesSorting}
     * @memberof FishingFacilitiesParams
     */
    'sorting': FishingFacilitiesSorting | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FishingFacilitiesSorting = {
    Setup: 'Setup',
    Removed: 'Removed',
    LastChanged: 'LastChanged'
} as const;

export type FishingFacilitiesSorting = typeof FishingFacilitiesSorting[keyof typeof FishingFacilitiesSorting];


/**
 * 
 * @export
 * @interface FishingFacility
 */
export interface FishingFacility {
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'toolId': string;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'barentswatchVesselId': string | null;
    /**
     * 
     * @type {number}
     * @memberof FishingFacility
     */
    'fiskeridirVesselId': number | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'vesselName': string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'callSign': string | null;
    /**
     * 
     * @type {number}
     * @memberof FishingFacility
     */
    'mmsi': number | null;
    /**
     * 
     * @type {number}
     * @memberof FishingFacility
     */
    'imo': number | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'regNum': string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'sbrRegNum': string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'contactPhone': string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'contactEmail': string | null;
    /**
     * 
     * @type {FishingFacilityToolType}
     * @memberof FishingFacility
     */
    'toolType': FishingFacilityToolType;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'toolTypeName': string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'toolColor': string | null;
    /**
     * 
     * @type {number}
     * @memberof FishingFacility
     */
    'toolCount': number | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'setupTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'setupProcessedTimestamp': string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'removedTimestamp': string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'removedProcessedTimestamp': string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'lastChanged': string;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'source': string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof FishingFacility
     */
    'geometryWkt': string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FishingFacilityToolType = {
    Undefined: 'Undefined',
    Crabpot: 'Crabpot',
    Danpurseine: 'Danpurseine',
    Nets: 'Nets',
    Longline: 'Longline',
    Generic: 'Generic',
    Sensorbuoy: 'Sensorbuoy',
    Sensorcable: 'Sensorcable',
    Unknown: 'Unknown',
    Seismic: 'Seismic',
    Mooring: 'Mooring',
    PlannedCableLaying: 'PlannedCableLaying'
} as const;

export type FishingFacilityToolType = typeof FishingFacilityToolType[keyof typeof FishingFacilityToolType];


/**
 * 
 * @export
 * @interface FishingPredictionsPath
 */
export interface FishingPredictionsPath {
    /**
     * 
     * @type {ModelId}
     * @memberof FishingPredictionsPath
     */
    'model_id': ModelId;
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof FishingPredictionsPath
     */
    'species_group_id': SpeciesGroup;
}


/**
 * 
 * @export
 * @interface FishingSpotPrediction
 */
export interface FishingSpotPrediction {
    /**
     * 
     * @type {number}
     * @memberof FishingSpotPrediction
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof FishingSpotPrediction
     */
    'longitude': number;
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof FishingSpotPrediction
     */
    'speciesGroupId': SpeciesGroup;
    /**
     * 
     * @type {string}
     * @memberof FishingSpotPrediction
     */
    'date': string;
}


/**
 * 
 * @export
 * @interface FishingSpotPredictionParams
 */
export interface FishingSpotPredictionParams {
    /**
     * 
     * @type {string}
     * @memberof FishingSpotPredictionParams
     */
    'date': string | null;
}
/**
 * 
 * @export
 * @interface FishingWeightPrediction
 */
export interface FishingWeightPrediction {
    /**
     * 
     * @type {string}
     * @memberof FishingWeightPrediction
     */
    'catchLocationId': string;
    /**
     * 
     * @type {number}
     * @memberof FishingWeightPrediction
     */
    'weight': number;
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof FishingWeightPrediction
     */
    'speciesGroupId': SpeciesGroup;
    /**
     * 
     * @type {string}
     * @memberof FishingWeightPrediction
     */
    'date': string;
}


/**
 * 
 * @export
 * @interface FishingWeightPredictionParams
 */
export interface FishingWeightPredictionParams {
    /**
     * 
     * @type {string}
     * @memberof FishingWeightPredictionParams
     */
    'date': string | null;
    /**
     * 
     * @type {number}
     * @memberof FishingWeightPredictionParams
     */
    'limit': number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FiskdirVesselNationalityGroup = {
    U: 'U',
    N: 'N',
    T: 'T'
} as const;

export type FiskdirVesselNationalityGroup = typeof FiskdirVesselNationalityGroup[keyof typeof FiskdirVesselNationalityGroup];


/**
 * 
 * @export
 * @interface FiskeridirVessel
 */
export interface FiskeridirVessel {
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'vesselTypeId': number | null;
    /**
     * 
     * @type {VesselLengthGroup}
     * @memberof FiskeridirVessel
     */
    'lengthGroupId': VesselLengthGroup;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'nationGroupId': string | null;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'nationId': string | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'norwegianMunicipalityId': number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'norwegianCountyId': number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'grossTonnage1969': number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'grossTonnageOther': number | null;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'callSign': string | null;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'registrationId': string | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'length': number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'width': number | null;
    /**
     * 
     * @type {string}
     * @memberof FiskeridirVessel
     */
    'owner': string | null;
    /**
     * 
     * @type {Array<RegisterVesselOwner>}
     * @memberof FiskeridirVessel
     */
    'owners': Array<RegisterVesselOwner>;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'engineBuildingYear': number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'enginePower': number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'buildingYear': number | null;
    /**
     * 
     * @type {number}
     * @memberof FiskeridirVessel
     */
    'rebuildingYear': number | null;
}


/**
 * 
 * @export
 * @interface FuelEntry
 */
export interface FuelEntry {
    /**
     * 
     * @type {number}
     * @memberof FuelEntry
     */
    'fiskeridirVesselId': number;
    /**
     * 
     * @type {number}
     * @memberof FuelEntry
     */
    'estimatedFuel': number;
}
/**
 * 
 * @export
 * @interface FuelMeasurement
 */
export interface FuelMeasurement {
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurement
     */
    'barentswatchUserId': string;
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurement
     */
    'callSign': string;
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurement
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof FuelMeasurement
     */
    'fuel': number;
}
/**
 * 
 * @export
 * @interface FuelMeasurementBody
 */
export interface FuelMeasurementBody {
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurementBody
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof FuelMeasurementBody
     */
    'fuel': number;
}
/**
 * 
 * @export
 * @interface FuelMeasurementsParams
 */
export interface FuelMeasurementsParams {
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurementsParams
     */
    'startDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurementsParams
     */
    'endDate': string | null;
}
/**
 * 
 * @export
 * @interface FuelParams
 */
export interface FuelParams {
    /**
     * 
     * @type {string}
     * @memberof FuelParams
     */
    'startDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof FuelParams
     */
    'endDate': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Gear = {
    Unknown: 'Unknown',
    UndefinedSeine: 'UndefinedSeine',
    PurseSeine: 'PurseSeine',
    BeachSeine: 'BeachSeine',
    PurseSeineWithLight: 'PurseSeineWithLight',
    BeachSeineWithLight: 'BeachSeineWithLight',
    UndefinedNet: 'UndefinedNet',
    DriftNet: 'DriftNet',
    GillNet: 'GillNet',
    UndefinedHookGear: 'UndefinedHookGear',
    FloatingLine: 'FloatingLine',
    OtherLines: 'OtherLines',
    Jig: 'Jig',
    DorgHarpSnik: 'DorgHarpSnik',
    AutoLine: 'AutoLine',
    UndefinedLobsterTrapAndFykeNets: 'UndefinedLobsterTrapAndFykeNets',
    FykeNets: 'FykeNets',
    LobsterTraps: 'LobsterTraps',
    WedgeSeine: 'WedgeSeine',
    OceanLobsterTraps: 'OceanLobsterTraps',
    HookNet: 'HookNet',
    UndefinedTrawling: 'UndefinedTrawling',
    BottomTrawl: 'BottomTrawl',
    BottomTrawlPair: 'BottomTrawlPair',
    MidwaterTrawl: 'MidwaterTrawl',
    MidwaterTrawlPair: 'MidwaterTrawlPair',
    ShrimpTrawl: 'ShrimpTrawl',
    BeamTrawl: 'BeamTrawl',
    CrayfishTrawl: 'CrayfishTrawl',
    DoubleTrawl: 'DoubleTrawl',
    TripleTrawl: 'TripleTrawl',
    DanishSeine: 'DanishSeine',
    Harpoon: 'Harpoon',
    BaskingSharkWhaleCannon: 'BaskingSharkWhaleCannon',
    BigHarpoon: 'BigHarpoon',
    Rifle: 'Rifle',
    Other: 'Other',
    ShellScrape: 'ShellScrape',
    HandNet: 'HandNet',
    KelpTrawl: 'KelpTrawl',
    SeaweedCutter: 'SeaweedCutter',
    HandPicking: 'HandPicking',
    ShellSucker: 'ShellSucker',
    FishFarming: 'FishFarming',
    Unspecified: 'Unspecified'
} as const;

export type Gear = typeof Gear[keyof typeof Gear];


/**
 * 
 * @export
 * @interface GearDetailed
 */
export interface GearDetailed {
    /**
     * 
     * @type {Gear}
     * @memberof GearDetailed
     */
    'id': Gear;
    /**
     * 
     * @type {string}
     * @memberof GearDetailed
     */
    'name': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const GearGroup = {
    Unknown: 'Unknown',
    Seine: 'Seine',
    Net: 'Net',
    HookGear: 'HookGear',
    LobsterTrapAndFykeNets: 'LobsterTrapAndFykeNets',
    Trawl: 'Trawl',
    DanishSeine: 'DanishSeine',
    HarpoonCannon: 'HarpoonCannon',
    OtherGear: 'OtherGear',
    FishFarming: 'FishFarming'
} as const;

export type GearGroup = typeof GearGroup[keyof typeof GearGroup];


/**
 * 
 * @export
 * @interface GearGroupDetailed
 */
export interface GearGroupDetailed {
    /**
     * 
     * @type {GearGroup}
     * @memberof GearGroupDetailed
     */
    'id': GearGroup;
    /**
     * 
     * @type {string}
     * @memberof GearGroupDetailed
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface GearMainGroupDetailed
 */
export interface GearMainGroupDetailed {
    /**
     * 
     * @type {MainGearGroup}
     * @memberof GearMainGroupDetailed
     */
    'id': MainGearGroup;
    /**
     * 
     * @type {string}
     * @memberof GearMainGroupDetailed
     */
    'name': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const HasTrack = {
    NoTrack: 'NoTrack',
    TrackUnder15: 'TrackUnder15',
    TrackOver15: 'TrackOver15'
} as const;

export type HasTrack = typeof HasTrack[keyof typeof HasTrack];


/**
 * 
 * @export
 * @interface Haul
 */
export interface Haul {
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'haulId': number;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'haulDistance': number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Haul
     */
    'catchLocations': Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'startLatitude': number;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'startLongitude': number;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'stopLatitude': number;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'stopLongitude': number;
    /**
     * 
     * @type {string}
     * @memberof Haul
     */
    'startTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Haul
     */
    'stopTimestamp': string;
    /**
     * 
     * @type {GearGroup}
     * @memberof Haul
     */
    'gearGroupId': GearGroup;
    /**
     * 
     * @type {Gear}
     * @memberof Haul
     */
    'gear': Gear;
    /**
     * 
     * @type {Array<HaulCatch>}
     * @memberof Haul
     */
    'catches': Array<HaulCatch>;
    /**
     * 
     * @type {number}
     * @memberof Haul
     */
    'fiskeridirVesselId': number | null;
    /**
     * 
     * @type {string}
     * @memberof Haul
     */
    'vesselName': string | null;
    /**
     * 
     * @type {string}
     * @memberof Haul
     */
    'callSign': string;
}


/**
 * 
 * @export
 * @interface HaulCatch
 */
export interface HaulCatch {
    /**
     * 
     * @type {number}
     * @memberof HaulCatch
     */
    'livingWeight': number;
    /**
     * 
     * @type {number}
     * @memberof HaulCatch
     */
    'speciesFiskeridirId': number;
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof HaulCatch
     */
    'speciesGroupId': SpeciesGroup;
}


/**
 * 
 * @export
 * @interface HaulOceanClimate
 */
export interface HaulOceanClimate {
    /**
     * 
     * @type {number}
     * @memberof HaulOceanClimate
     */
    'waterSpeed': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulOceanClimate
     */
    'waterDirection': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulOceanClimate
     */
    'salinity': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulOceanClimate
     */
    'waterTemperature': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulOceanClimate
     */
    'oceanClimateDepth': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulOceanClimate
     */
    'seaFloorDepth': number | null;
}
/**
 * 
 * @export
 * @interface HaulWeather
 */
export interface HaulWeather {
    /**
     * 
     * @type {number}
     * @memberof HaulWeather
     */
    'windSpeed10m': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulWeather
     */
    'windDirection10m': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulWeather
     */
    'airTemperature2m': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulWeather
     */
    'relativeHumidity2m': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulWeather
     */
    'airPressureAtSeaLevel': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulWeather
     */
    'precipitationAmount': number | null;
    /**
     * 
     * @type {number}
     * @memberof HaulWeather
     */
    'cloudAreaFraction': number | null;
}
/**
 * 
 * @export
 * @interface HaulsMatrix
 */
export interface HaulsMatrix {
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsMatrix
     */
    'dates': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsMatrix
     */
    'lengthGroup': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsMatrix
     */
    'gearGroup': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsMatrix
     */
    'speciesGroup': Array<number>;
}
/**
 * 
 * @export
 * @interface HaulsMatrixParams
 */
export interface HaulsMatrixParams {
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsMatrixParams
     */
    'months[]': Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HaulsMatrixParams
     */
    'catchLocations[]': Array<string> | null;
    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof HaulsMatrixParams
     */
    'gearGroupIds[]': Array<GearGroup> | null;
    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof HaulsMatrixParams
     */
    'speciesGroupIds[]': Array<SpeciesGroup> | null;
    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof HaulsMatrixParams
     */
    'vesselLengthGroups[]': Array<VesselLengthGroup> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsMatrixParams
     */
    'fiskeridirVesselIds[]': Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof HaulsMatrixParams
     */
    'bycatchPercentage': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof HaulsMatrixParams
     */
    'majoritySpeciesGroup': boolean | null;
}
/**
 * 
 * @export
 * @interface HaulsMatrixPath
 */
export interface HaulsMatrixPath {
    /**
     * 
     * @type {ActiveHaulsFilter}
     * @memberof HaulsMatrixPath
     */
    'active_filter': ActiveHaulsFilter;
}


/**
 * 
 * @export
 * @interface HaulsParams
 */
export interface HaulsParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof HaulsParams
     */
    'months[]': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HaulsParams
     */
    'catchLocations[]': Array<string> | null;
    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof HaulsParams
     */
    'gearGroupIds[]': Array<GearGroup> | null;
    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof HaulsParams
     */
    'speciesGroupIds[]': Array<SpeciesGroup> | null;
    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof HaulsParams
     */
    'vesselLengthGroups[]': Array<VesselLengthGroup> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof HaulsParams
     */
    'fiskeridirVesselIds[]': Array<number> | null;
    /**
     * 
     * @type {HaulsSorting}
     * @memberof HaulsParams
     */
    'sorting': HaulsSorting | null;
    /**
     * 
     * @type {Ordering}
     * @memberof HaulsParams
     */
    'ordering': Ordering | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const HaulsSorting = {
    StartDate: 'StartDate',
    StopDate: 'StopDate',
    Weight: 'Weight'
} as const;

export type HaulsSorting = typeof HaulsSorting[keyof typeof HaulsSorting];


/**
 * 
 * @export
 * @interface Landing
 */
export interface Landing {
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'landingTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'catchLocation': string | null;
    /**
     * 
     * @type {Gear}
     * @memberof Landing
     */
    'gearId': Gear;
    /**
     * 
     * @type {GearGroup}
     * @memberof Landing
     */
    'gearGroupId': GearGroup;
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'deliveryPointId': string | null;
    /**
     * 
     * @type {number}
     * @memberof Landing
     */
    'fiskeridirVesselId': number | null;
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'vesselCallSign': string | null;
    /**
     * 
     * @type {string}
     * @memberof Landing
     */
    'vesselName': string | null;
    /**
     * 
     * @type {number}
     * @memberof Landing
     */
    'vesselLength': number | null;
    /**
     * 
     * @type {VesselLengthGroup}
     * @memberof Landing
     */
    'vesselLengthGroup': VesselLengthGroup;
    /**
     * 
     * @type {number}
     * @memberof Landing
     */
    'totalLivingWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Landing
     */
    'totalProductWeight': number;
    /**
     * 
     * @type {number}
     * @memberof Landing
     */
    'totalGrossWeight': number;
    /**
     * 
     * @type {Array<LandingCatch>}
     * @memberof Landing
     */
    'catches': Array<LandingCatch>;
}


/**
 * 
 * @export
 * @interface LandingCatch
 */
export interface LandingCatch {
    /**
     * 
     * @type {number}
     * @memberof LandingCatch
     */
    'livingWeight': number;
    /**
     * 
     * @type {number}
     * @memberof LandingCatch
     */
    'grossWeight': number;
    /**
     * 
     * @type {number}
     * @memberof LandingCatch
     */
    'productWeight': number;
    /**
     * 
     * @type {number}
     * @memberof LandingCatch
     */
    'speciesFiskeridirId': number;
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof LandingCatch
     */
    'speciesGroupId': SpeciesGroup;
}


/**
 * 
 * @export
 * @interface LandingMatrix
 */
export interface LandingMatrix {
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingMatrix
     */
    'dates': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingMatrix
     */
    'lengthGroup': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingMatrix
     */
    'gearGroup': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingMatrix
     */
    'speciesGroup': Array<number>;
}
/**
 * 
 * @export
 * @interface LandingMatrixParams
 */
export interface LandingMatrixParams {
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingMatrixParams
     */
    'months[]': Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LandingMatrixParams
     */
    'catchLocations[]': Array<string> | null;
    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof LandingMatrixParams
     */
    'gearGroupIds[]': Array<GearGroup> | null;
    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof LandingMatrixParams
     */
    'speciesGroupIds[]': Array<SpeciesGroup> | null;
    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof LandingMatrixParams
     */
    'vesselLengthGroups[]': Array<VesselLengthGroup> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingMatrixParams
     */
    'fiskeridirVesselIds[]': Array<number> | null;
}
/**
 * 
 * @export
 * @interface LandingMatrixPath
 */
export interface LandingMatrixPath {
    /**
     * 
     * @type {ActiveLandingFilter}
     * @memberof LandingMatrixPath
     */
    'active_filter': ActiveLandingFilter;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LandingMonth = {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    Oktober: 'Oktober',
    November: 'November',
    December: 'December',
    NextYear: 'NextYear'
} as const;

export type LandingMonth = typeof LandingMonth[keyof typeof LandingMonth];


/**
 * 
 * @export
 * @interface LandingsParams
 */
export interface LandingsParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof LandingsParams
     */
    'months[]': Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LandingsParams
     */
    'catchLocations[]': Array<string> | null;
    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof LandingsParams
     */
    'gearGroupIds[]': Array<GearGroup> | null;
    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof LandingsParams
     */
    'speciesGroupIds[]': Array<SpeciesGroup> | null;
    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof LandingsParams
     */
    'vesselLengthGroups[]': Array<VesselLengthGroup> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LandingsParams
     */
    'fiskeridirVesselIds[]': Array<number> | null;
    /**
     * 
     * @type {LandingsSorting}
     * @memberof LandingsParams
     */
    'sorting': LandingsSorting | null;
    /**
     * 
     * @type {Ordering}
     * @memberof LandingsParams
     */
    'ordering': Ordering | null;
    /**
     * 
     * @type {number}
     * @memberof LandingsParams
     */
    'limit': number | null;
    /**
     * 
     * @type {number}
     * @memberof LandingsParams
     */
    'offset': number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LandingsSorting = {
    LandingTimestamp: 'LandingTimestamp',
    LivingWeight: 'LivingWeight'
} as const;

export type LandingsSorting = typeof LandingsSorting[keyof typeof LandingsSorting];


/**
 * 
 * @export
 * @interface LiveFuel
 */
export interface LiveFuel {
    /**
     * 
     * @type {number}
     * @memberof LiveFuel
     */
    'total_fuel': number;
    /**
     * 
     * @type {Array<LiveFuelEntry>}
     * @memberof LiveFuel
     */
    'entries': Array<LiveFuelEntry>;
}
/**
 * 
 * @export
 * @interface LiveFuelEntry
 */
export interface LiveFuelEntry {
    /**
     * 
     * @type {string}
     * @memberof LiveFuelEntry
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof LiveFuelEntry
     */
    'fuel': number;
}
/**
 * 
 * @export
 * @interface LiveFuelParams
 */
export interface LiveFuelParams {
    /**
     * 
     * @type {string}
     * @memberof LiveFuelParams
     */
    'threshold': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MainGearGroup = {
    Unknown: 'Unknown',
    Trawl: 'Trawl',
    Seine: 'Seine',
    Conventional: 'Conventional',
    Other: 'Other'
} as const;

export type MainGearGroup = typeof MainGearGroup[keyof typeof MainGearGroup];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelId = {
    Spot: 'Spot',
    Weight: 'Weight',
    WeightWeather: 'WeightWeather',
    SpotWeather: 'SpotWeather'
} as const;

export type ModelId = typeof ModelId[keyof typeof ModelId];


/**
 * 
 * @export
 * @enum {string}
 */

export const Month = {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December'
} as const;

export type Month = typeof Month[keyof typeof Month];


/**
 * 
 * @export
 * @enum {string}
 */

export const NavigationStatus = {
    UnderWayUsingEngine: 'UnderWayUsingEngine',
    AtAnchor: 'AtAnchor',
    NotUnderCommand: 'NotUnderCommand',
    RestrictedManoeuverability: 'RestrictedManoeuverability',
    ConstrainedByDraught: 'ConstrainedByDraught',
    Moored: 'Moored',
    Aground: 'Aground',
    EngagedInFishing: 'EngagedInFishing',
    UnderWaySailing: 'UnderWaySailing',
    Reserved9: 'Reserved9',
    Reserved10: 'Reserved10',
    Reserved11: 'Reserved11',
    Reserved12: 'Reserved12',
    Reserved13: 'Reserved13',
    AisSartIsActive: 'AisSartIsActive',
    NotDefined: 'NotDefined'
} as const;

export type NavigationStatus = typeof NavigationStatus[keyof typeof NavigationStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const Ordering = {
    Asc: 'Asc',
    Desc: 'Desc'
} as const;

export type Ordering = typeof Ordering[keyof typeof Ordering];


/**
 * 
 * @export
 * @interface OrgBenchmarkEntry
 */
export interface OrgBenchmarkEntry {
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarkEntry
     */
    'fiskeridirVesselId': number;
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarkEntry
     */
    'fishingTime': number;
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarkEntry
     */
    'tripDistance': number;
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarkEntry
     */
    'tripTime': number;
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarkEntry
     */
    'landingTotalLivingWeight': number;
    /**
     * 
     * @type {Array<OrgBenchmarkSpecies>}
     * @memberof OrgBenchmarkEntry
     */
    'species': Array<OrgBenchmarkSpecies>;
}
/**
 * 
 * @export
 * @interface OrgBenchmarkParameters
 */
export interface OrgBenchmarkParameters {
    /**
     * 
     * @type {string}
     * @memberof OrgBenchmarkParameters
     */
    'start': string | null;
    /**
     * 
     * @type {string}
     * @memberof OrgBenchmarkParameters
     */
    'end': string | null;
}
/**
 * 
 * @export
 * @interface OrgBenchmarkPath
 */
export interface OrgBenchmarkPath {
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarkPath
     */
    'org_id': number;
}
/**
 * 
 * @export
 * @interface OrgBenchmarkSpecies
 */
export interface OrgBenchmarkSpecies {
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof OrgBenchmarkSpecies
     */
    'speciesGroupId': SpeciesGroup;
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarkSpecies
     */
    'landingTotalLivingWeight': number;
}


/**
 * 
 * @export
 * @interface OrgBenchmarks
 */
export interface OrgBenchmarks {
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarks
     */
    'fishingTime': number;
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarks
     */
    'tripDistance': number;
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarks
     */
    'tripTime': number;
    /**
     * 
     * @type {number}
     * @memberof OrgBenchmarks
     */
    'landingTotalLivingWeight': number;
    /**
     * 
     * @type {Array<OrgBenchmarkEntry>}
     * @memberof OrgBenchmarks
     */
    'vessels': Array<OrgBenchmarkEntry>;
}
/**
 * 
 * @export
 * @interface OrgFuelPath
 */
export interface OrgFuelPath {
    /**
     * 
     * @type {number}
     * @memberof OrgFuelPath
     */
    'org_id': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Quality = {
    Extra: 'Extra',
    Prima: 'Prima',
    Superior: 'Superior',
    A: 'A',
    Blank: 'Blank',
    B: 'B',
    Sekunda: 'Sekunda',
    Africa: 'Africa',
    FrostDamagedFish: 'FrostDamagedFish',
    Yellow: 'Yellow',
    ProductionRoe: 'ProductionRoe',
    CrackedCrab: 'CrackedCrab',
    WetCrab: 'WetCrab',
    WrongCut: 'WrongCut',
    Injured: 'Injured',
    Offal: 'Offal',
    Wrek: 'Wrek',
    Unspecified: 'Unspecified'
} as const;

export type Quality = typeof Quality[keyof typeof Quality];


/**
 * 
 * @export
 * @enum {string}
 */

export const Quota = {
    Unknown: 'Unknown',
    RegularQuota: 'RegularQuota',
    ResearchQuota: 'ResearchQuota',
    SchoolQuota: 'SchoolQuota',
    OtherCountryQuota: 'OtherCountryQuota',
    YouthQuota: 'YouthQuota',
    RecreationalFishing: 'RecreationalFishing',
    RagularQuotaWithDeliveryConditions: 'RagularQuotaWithDeliveryConditions',
    DistrictQuota: 'DistrictQuota',
    BaitQuota: 'BaitQuota',
    ReqularQuotaSaleToTourists: 'ReqularQuotaSaleToTourists',
    KingCrab: 'KingCrab',
    BonusQuotaFresh: 'BonusQuotaFresh',
    BonusQuotaLiveStorage: 'BonusQuotaLiveStorage',
    StudentQuota: 'StudentQuota',
    AdditionalQuota: 'AdditionalQuota'
} as const;

export type Quota = typeof Quota[keyof typeof Quota];


/**
 * 
 * @export
 * @enum {string}
 */

export const RegisterVesselEntityType = {
    Company: 'COMPANY',
    Person: 'PERSON'
} as const;

export type RegisterVesselEntityType = typeof RegisterVesselEntityType[keyof typeof RegisterVesselEntityType];


/**
 * 
 * @export
 * @interface RegisterVesselOwner
 */
export interface RegisterVesselOwner {
    /**
     * 
     * @type {string}
     * @memberof RegisterVesselOwner
     */
    'city': string | null;
    /**
     * 
     * @type {RegisterVesselEntityType}
     * @memberof RegisterVesselOwner
     */
    'entityType': RegisterVesselEntityType;
    /**
     * 
     * @type {number}
     * @memberof RegisterVesselOwner
     */
    'id': number | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterVesselOwner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RegisterVesselOwner
     */
    'postalCode': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SalesTeam = {
    FiskehavSa: 'FiskehavSA',
    RogalandFiskesalgslagSl: 'RogalandFiskesalgslagSL',
    VestNorgesFiskesalslag: 'VestNorgesFiskesalslag',
    SunnmoreOgRomsdalFiskesalslag: 'SunnmoreOgRomsdalFiskesalslag',
    NorgesRafisklag: 'NorgesRafisklag',
    NorgesSildesalgslag: 'NorgesSildesalgslag',
    CatchRegisteredInAnotherWay: 'CatchRegisteredInAnotherWay'
} as const;

export type SalesTeam = typeof SalesTeam[keyof typeof SalesTeam];


/**
 * 
 * @export
 * @interface Species
 */
export interface Species {
    /**
     * 
     * @type {number}
     * @memberof Species
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Species
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SpeciesFao
 */
export interface SpeciesFao {
    /**
     * 
     * @type {string}
     * @memberof SpeciesFao
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SpeciesFao
     */
    'name': string | null;
}
/**
 * 
 * @export
 * @interface SpeciesFiskeridir
 */
export interface SpeciesFiskeridir {
    /**
     * 
     * @type {number}
     * @memberof SpeciesFiskeridir
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SpeciesFiskeridir
     */
    'name': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SpeciesGroup = {
    Unknown: 'Unknown',
    Capelin: 'Capelin',
    NorwegianSpringSpawningHerring: 'NorwegianSpringSpawningHerring',
    OtherHerring: 'OtherHerring',
    Mackerel: 'Mackerel',
    BlueWhiting: 'BlueWhiting',
    NorwayPout: 'NorwayPout',
    Sandeels: 'Sandeels',
    Argentines: 'Argentines',
    EuropeanSpratSea: 'EuropeanSpratSea',
    EuropeanSpratCoast: 'EuropeanSpratCoast',
    MesopelagicFish: 'MesopelagicFish',
    TunaAndTunaishSpecies: 'TunaAndTunaishSpecies',
    OtherPelagicFish: 'OtherPelagicFish',
    AtlanticCod: 'AtlanticCod',
    Haddock: 'Haddock',
    Saithe: 'Saithe',
    Gadiformes: 'Gadiformes',
    GreenlandHalibut: 'GreenlandHalibut',
    GoldenRedfish: 'GoldenRedfish',
    Wrasse: 'Wrasse',
    Wolffishes: 'Wolffishes',
    FlatFishOtherBottomFishAndDeepseaFish: 'FlatFishOtherBottomFishAndDeepseaFish',
    SharkFish: 'SharkFish',
    SkatesAndOtherChondrichthyes: 'SkatesAndOtherChondrichthyes',
    QueenCrab: 'QueenCrab',
    EdibleCrab: 'EdibleCrab',
    RedKingCrab: 'RedKingCrab',
    RedKingCrabOther: 'RedKingCrabOther',
    NorthernPrawn: 'NorthernPrawn',
    AntarcticKrill: 'AntarcticKrill',
    CalanusFinmarchicus: 'CalanusFinmarchicus',
    OtherShellfishMolluscaAndEchinoderm: 'OtherShellfishMolluscaAndEchinoderm',
    BrownSeaweed: 'BrownSeaweed',
    OtherSeaweed: 'OtherSeaweed',
    FreshWaterFish: 'FreshWaterFish',
    FishFarming: 'FishFarming',
    MarineMammals: 'MarineMammals',
    Seabird: 'Seabird',
    Other: 'Other'
} as const;

export type SpeciesGroup = typeof SpeciesGroup[keyof typeof SpeciesGroup];


/**
 * 
 * @export
 * @interface SpeciesGroupDetailed
 */
export interface SpeciesGroupDetailed {
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof SpeciesGroupDetailed
     */
    'id': SpeciesGroup;
    /**
     * 
     * @type {string}
     * @memberof SpeciesGroupDetailed
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface SpeciesGroupParams
 */
export interface SpeciesGroupParams {
    /**
     * 
     * @type {boolean}
     * @memberof SpeciesGroupParams
     */
    'hasMlModels': boolean | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SpeciesMainGroup = {
    Unknown: 'Unknown',
    PelagicFish: 'PelagicFish',
    CodAndCodishFish: 'CodAndCodishFish',
    FlatFishOtherBottomFishAndDeepseaFish: 'FlatFishOtherBottomFishAndDeepseaFish',
    ChondrichthyesSharkFishSkatesRaysAndRabbitFish: 'ChondrichthyesSharkFishSkatesRaysAndRabbitFish',
    ShellfishMolluscaAndEchinoderm: 'ShellfishMolluscaAndEchinoderm',
    Seaweed: 'Seaweed',
    FishFarmingFreshWaterFishAndMarineMammals: 'FishFarmingFreshWaterFishAndMarineMammals'
} as const;

export type SpeciesMainGroup = typeof SpeciesMainGroup[keyof typeof SpeciesMainGroup];


/**
 * 
 * @export
 * @interface SpeciesMainGroupDetailed
 */
export interface SpeciesMainGroupDetailed {
    /**
     * 
     * @type {SpeciesMainGroup}
     * @memberof SpeciesMainGroupDetailed
     */
    'id': SpeciesMainGroup;
    /**
     * 
     * @type {string}
     * @memberof SpeciesMainGroupDetailed
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface Tra
 */
export interface Tra {
    /**
     * 
     * @type {number}
     * @memberof Tra
     */
    'fiskeridirVesselId': number | null;
    /**
     * 
     * @type {number}
     * @memberof Tra
     */
    'latitude': number | null;
    /**
     * 
     * @type {number}
     * @memberof Tra
     */
    'longitude': number | null;
    /**
     * 
     * @type {string}
     * @memberof Tra
     */
    'reloadingTimestamp': string | null;
    /**
     * 
     * @type {string}
     * @memberof Tra
     */
    'messageTimestamp': string;
    /**
     * 
     * @type {Array<TraCatch>}
     * @memberof Tra
     */
    'catches': Array<TraCatch>;
    /**
     * 
     * @type {number}
     * @memberof Tra
     */
    'reloadToFiskeridirVesselId': number | null;
    /**
     * 
     * @type {number}
     * @memberof Tra
     */
    'reloadFromFiskeridirVesselId': number | null;
    /**
     * 
     * @type {string}
     * @memberof Tra
     */
    'reloadToCallSign': string | null;
    /**
     * 
     * @type {string}
     * @memberof Tra
     */
    'reloadFromCallSign': string | null;
}
/**
 * 
 * @export
 * @interface TraCatch
 */
export interface TraCatch {
    /**
     * 
     * @type {number}
     * @memberof TraCatch
     */
    'livingWeight': number;
    /**
     * 
     * @type {SpeciesGroup}
     * @memberof TraCatch
     */
    'speciesGroupId': SpeciesGroup;
    /**
     * 
     * @type {ErsQuantumType}
     * @memberof TraCatch
     */
    'catchQuantum': ErsQuantumType;
}


/**
 * 
 * @export
 * @interface Trip
 */
export interface Trip {
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'fiskeridirVesselId': number;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'tripId': number;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'landingCoverageStart': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'landingCoverageEnd': string;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'numDeliveries': number;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'mostRecentDeliveryDate': string | null;
    /**
     * 
     * @type {Array<Gear>}
     * @memberof Trip
     */
    'gearIds': Array<Gear>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Trip
     */
    'deliveryPointIds': Array<string>;
    /**
     * 
     * @type {Array<Haul>}
     * @memberof Trip
     */
    'hauls': Array<Haul>;
    /**
     * 
     * @type {Array<Tra>}
     * @memberof Trip
     */
    'tra': Array<Tra>;
    /**
     * 
     * @type {Array<FishingFacility>}
     * @memberof Trip
     */
    'fishingFacilities': Array<FishingFacility>;
    /**
     * 
     * @type {Delivery}
     * @memberof Trip
     */
    'delivery': Delivery;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'startPortId': string | null;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'endPortId': string | null;
    /**
     * 
     * @type {Array<VesselEvent>}
     * @memberof Trip
     */
    'events': Array<VesselEvent>;
    /**
     * 
     * @type {TripAssemblerId}
     * @memberof Trip
     */
    'tripAssemblerId': TripAssemblerId;
    /**
     * 
     * @type {Array<string>}
     * @memberof Trip
     */
    'landingIds': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'targetSpeciesFiskeridirId': number | null;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'targetSpeciesFaoId': string | null;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'fuelConsumption': number | null;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'trackCoverage': number | null;
    /**
     * 
     * @type {number}
     * @memberof Trip
     */
    'distance': number | null;
    /**
     * 
     * @type {HasTrack}
     * @memberof Trip
     */
    'hasTrack': HasTrack;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TripAssemblerId = {
    Landings: 'Landings',
    Ers: 'Ers'
} as const;

export type TripAssemblerId = typeof TripAssemblerId[keyof typeof TripAssemblerId];


/**
 * 
 * @export
 * @interface TripBenchmark
 */
export interface TripBenchmark {
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TripBenchmark
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof TripBenchmark
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'weightPerHour': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'weightPerDistance': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'fuelConsumption': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'weightPerFuel': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'catchValuePerFuel': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmark
     */
    'eeoi': number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TripBenchmarkId = {
    WeightPerHour: 'WeightPerHour',
    Sustainability: 'Sustainability',
    WeightPerDistance: 'WeightPerDistance',
    FuelConsumption: 'FuelConsumption',
    WeightPerFuel: 'WeightPerFuel',
    CatchValuePerFuel: 'CatchValuePerFuel',
    Eeoi: 'Eeoi'
} as const;

export type TripBenchmarkId = typeof TripBenchmarkId[keyof typeof TripBenchmarkId];


/**
 * 
 * @export
 * @enum {string}
 */

export const TripBenchmarkStatus = {
    MustRecompute: 'MustRecompute',
    MustRefresh: 'MustRefresh',
    Refreshed: 'Refreshed'
} as const;

export type TripBenchmarkStatus = typeof TripBenchmarkStatus[keyof typeof TripBenchmarkStatus];


/**
 * 
 * @export
 * @interface TripBenchmarks
 */
export interface TripBenchmarks {
    /**
     * 
     * @type {number}
     * @memberof TripBenchmarks
     */
    'weightPerHour': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmarks
     */
    'weightPerDistance': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmarks
     */
    'fuelConsumption': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmarks
     */
    'weightPerFuel': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripBenchmarks
     */
    'catchValuePerFuel': number | null;
    /**
     * 
     * @type {Array<TripBenchmark>}
     * @memberof TripBenchmarks
     */
    'trips': Array<TripBenchmark>;
}
/**
 * 
 * @export
 * @interface TripBenchmarksParams
 */
export interface TripBenchmarksParams {
    /**
     * 
     * @type {string}
     * @memberof TripBenchmarksParams
     */
    'startDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof TripBenchmarksParams
     */
    'endDate': string | null;
    /**
     * 
     * @type {Ordering}
     * @memberof TripBenchmarksParams
     */
    'ordering': Ordering | null;
}


/**
 * 
 * @export
 * @interface TripOfHaulPath
 */
export interface TripOfHaulPath {
    /**
     * 
     * @type {number}
     * @memberof TripOfHaulPath
     */
    'haul_id': number;
}
/**
 * 
 * @export
 * @interface TripOfLandingPath
 */
export interface TripOfLandingPath {
    /**
     * 
     * @type {string}
     * @memberof TripOfLandingPath
     */
    'landing_id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TripPositionLayerId = {
    UnrealisticSpeed: 'UnrealisticSpeed',
    Cluster: 'Cluster',
    AisVmsConflict: 'AisVmsConflict'
} as const;

export type TripPositionLayerId = typeof TripPositionLayerId[keyof typeof TripPositionLayerId];


/**
 * 
 * @export
 * @enum {string}
 */

export const TripSorting = {
    StopDate: 'StopDate',
    Weight: 'Weight'
} as const;

export type TripSorting = typeof TripSorting[keyof typeof TripSorting];


/**
 * 
 * @export
 * @interface TripsParameters
 */
export interface TripsParameters {
    /**
     * 
     * @type {number}
     * @memberof TripsParameters
     */
    'limit': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripsParameters
     */
    'offset': number | null;
    /**
     * 
     * @type {Ordering}
     * @memberof TripsParameters
     */
    'ordering': Ordering | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TripsParameters
     */
    'deliveryPoints[]': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof TripsParameters
     */
    'startDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof TripsParameters
     */
    'endDate': string | null;
    /**
     * 
     * @type {number}
     * @memberof TripsParameters
     */
    'minWeight': number | null;
    /**
     * 
     * @type {number}
     * @memberof TripsParameters
     */
    'maxWeight': number | null;
    /**
     * 
     * @type {TripSorting}
     * @memberof TripsParameters
     */
    'sorting': TripSorting | null;
    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof TripsParameters
     */
    'gearGroupIds[]': Array<GearGroup> | null;
    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof TripsParameters
     */
    'speciesGroupIds[]': Array<SpeciesGroup> | null;
    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof TripsParameters
     */
    'vesselLengthGroups[]': Array<VesselLengthGroup> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TripsParameters
     */
    'fiskeridirVesselIds[]': Array<number> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TwelveMileBorder = {
    Outside: 'Outside',
    Within: 'Within',
    Unknown: 'Unknown'
} as const;

export type TwelveMileBorder = typeof TwelveMileBorder[keyof typeof TwelveMileBorder];


/**
 * 
 * @export
 * @interface UpdateVessel
 */
export interface UpdateVessel {
    /**
     * 
     * @type {number}
     * @memberof UpdateVessel
     */
    'enginePower': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateVessel
     */
    'engineBuildingYear': number | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {Array<number>}
     * @memberof User
     */
    'following': Array<number>;
}
/**
 * 
 * @export
 * @interface Vessel
 */
export interface Vessel {
    /**
     * 
     * @type {FiskeridirVessel}
     * @memberof Vessel
     */
    'fiskeridir': FiskeridirVessel;
    /**
     * 
     * @type {AisVessel}
     * @memberof Vessel
     */
    'ais': AisVessel | null;
    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof Vessel
     */
    'gearGroups': Array<GearGroup>;
    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof Vessel
     */
    'speciesGroups': Array<SpeciesGroup>;
}
/**
 * 
 * @export
 * @interface VesselBenchmarks
 */
export interface VesselBenchmarks {
    /**
     * 
     * @type {Benchmark}
     * @memberof VesselBenchmarks
     */
    'fishingTime': Benchmark | null;
    /**
     * 
     * @type {Benchmark}
     * @memberof VesselBenchmarks
     */
    'fishingDistance': Benchmark | null;
    /**
     * 
     * @type {Benchmark}
     * @memberof VesselBenchmarks
     */
    'tripTime': Benchmark | null;
    /**
     * 
     * @type {Benchmark}
     * @memberof VesselBenchmarks
     */
    'landings': Benchmark | null;
    /**
     * 
     * @type {Benchmark}
     * @memberof VesselBenchmarks
     */
    'ersDca': Benchmark | null;
    /**
     * 
     * @type {Array<CumulativeLandings>}
     * @memberof VesselBenchmarks
     */
    'cumulativeLandings': Array<CumulativeLandings>;
}
/**
 * 
 * @export
 * @interface VesselEvent
 */
export interface VesselEvent {
    /**
     * 
     * @type {number}
     * @memberof VesselEvent
     */
    'eventId': number;
    /**
     * 
     * @type {VesselEventType}
     * @memberof VesselEvent
     */
    'eventType': VesselEventType;
    /**
     * 
     * @type {string}
     * @memberof VesselEvent
     */
    'eventName': string;
    /**
     * 
     * @type {string}
     * @memberof VesselEvent
     */
    'reportTimestamp': string;
    /**
     * 
     * @type {string}
     * @memberof VesselEvent
     */
    'occurenceTimestamp': string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VesselEventType = {
    Landing: 'Landing',
    ErsDca: 'ErsDca',
    ErsPor: 'ErsPor',
    ErsDep: 'ErsDep',
    ErsTra: 'ErsTra',
    Haul: 'Haul'
} as const;

export type VesselEventType = typeof VesselEventType[keyof typeof VesselEventType];


/**
 * 
 * @export
 * @enum {string}
 */

export const VesselLengthGroup = {
    Unknown: 'Unknown',
    UnderEleven: 'UnderEleven',
    ElevenToFifteen: 'ElevenToFifteen',
    FifteenToTwentyOne: 'FifteenToTwentyOne',
    TwentyTwoToTwentyEight: 'TwentyTwoToTwentyEight',
    TwentyEightAndAbove: 'TwentyEightAndAbove'
} as const;

export type VesselLengthGroup = typeof VesselLengthGroup[keyof typeof VesselLengthGroup];


/**
 * 
 * @export
 * @enum {string}
 */

export const VesselSource = {
    Landings: 'Landings',
    FiskeridirVesselRegister: 'FiskeridirVesselRegister'
} as const;

export type VesselSource = typeof VesselSource[keyof typeof VesselSource];


/**
 * 
 * @export
 * @enum {string}
 */

export const VesselType = {
    Unknown: 'Unknown',
    FishingVessel: 'FishingVessel',
    TransportVessel: 'TransportVessel',
    WellBoat: 'WellBoat',
    CharterVessel: 'CharterVessel',
    PurchaseVessel: 'PurchaseVessel',
    CoFishingVessel: 'CoFishingVessel',
    PairTrawlTeam: 'PairTrawlTeam',
    ResearchVessel: 'ResearchVessel',
    SchoolVessel: 'SchoolVessel',
    BeachSeineVessel: 'BeachSeineVessel',
    KelpTrawler: 'KelpTrawler',
    LeisureVessel: 'LeisureVessel',
    InvalidFishingVessel: 'InvalidFishingVessel',
    SeaweedHarvester: 'SeaweedHarvester',
    WithoutVessel: 'WithoutVessel'
} as const;

export type VesselType = typeof VesselType[keyof typeof VesselType];


/**
 * 
 * @export
 * @interface VmsParameters
 */
export interface VmsParameters {
    /**
     * 
     * @type {string}
     * @memberof VmsParameters
     */
    'start': string | null;
    /**
     * 
     * @type {string}
     * @memberof VmsParameters
     */
    'end': string | null;
}
/**
 * 
 * @export
 * @interface VmsPath
 */
export interface VmsPath {
    /**
     * 
     * @type {string}
     * @memberof VmsPath
     */
    'call_sign': string;
}
/**
 * 
 * @export
 * @interface VmsPosition
 */
export interface VmsPosition {
    /**
     * 
     * @type {number}
     * @memberof VmsPosition
     */
    'course': number | null;
    /**
     * 
     * @type {number}
     * @memberof VmsPosition
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof VmsPosition
     */
    'lon': number;
    /**
     * 
     * @type {number}
     * @memberof VmsPosition
     */
    'speed': number | null;
    /**
     * 
     * @type {string}
     * @memberof VmsPosition
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof VmsPosition
     */
    'distanceToShore': number;
}
/**
 * 
 * @export
 * @interface Weather
 */
export interface Weather {
    /**
     * 
     * @type {string}
     * @memberof Weather
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'altitude': number;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'windSpeed10m': number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'windDirection10m': number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'airTemperature2m': number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'relativeHumidity2m': number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'airPressureAtSeaLevel': number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'precipitationAmount': number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'landAreaFraction': number;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'cloudAreaFraction': number | null;
    /**
     * 
     * @type {number}
     * @memberof Weather
     */
    'weatherLocationId': number;
}
/**
 * 
 * @export
 * @interface WeatherLocation
 */
export interface WeatherLocation {
    /**
     * 
     * @type {number}
     * @memberof WeatherLocation
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof WeatherLocation
     */
    'polygon': string;
}
/**
 * 
 * @export
 * @interface WeatherParams
 */
export interface WeatherParams {
    /**
     * 
     * @type {string}
     * @memberof WeatherParams
     */
    'startDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof WeatherParams
     */
    'endDate': string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WeatherParams
     */
    'weatherLocationIds[]': Array<number> | null;
}
/**
 * 
 * @export
 * @interface WhaleCatch
 */
export interface WhaleCatch {
    /**
     * 
     * @type {number}
     * @memberof WhaleCatch
     */
    'blubberMeasureA': number | null;
    /**
     * 
     * @type {number}
     * @memberof WhaleCatch
     */
    'blubberMeasureB': number | null;
    /**
     * 
     * @type {number}
     * @memberof WhaleCatch
     */
    'blubberMeasureC': number | null;
    /**
     * 
     * @type {number}
     * @memberof WhaleCatch
     */
    'circumference': number | null;
    /**
     * 
     * @type {number}
     * @memberof WhaleCatch
     */
    'fetusLength': number | null;
    /**
     * 
     * @type {WhaleGender}
     * @memberof WhaleCatch
     */
    'genderId': WhaleGender | null;
    /**
     * 
     * @type {string}
     * @memberof WhaleCatch
     */
    'grenadeNumber': string;
    /**
     * 
     * @type {number}
     * @memberof WhaleCatch
     */
    'individualNumber': number | null;
    /**
     * 
     * @type {number}
     * @memberof WhaleCatch
     */
    'length': number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WhaleGender = {
    Male: 'Male',
    Female: 'Female'
} as const;

export type WhaleGender = typeof WhaleGender[keyof typeof WhaleGender];



/**
 * AisApi - axios parameter creator
 * @export
 */
export const AisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all current AIS positions of vessels. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {string | null} [positionTimestampLimit] 
         * @param {string} [bwToken] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1AisAisCurrentPositions: async (positionTimestampLimit?: string | null, bwToken?: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/ais_current_positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (positionTimestampLimit !== undefined) {
                localVarQueryParameter['positionTimestampLimit'] = (positionTimestampLimit as any instanceof Date) ?
                    (positionTimestampLimit as any).toISOString() :
                    positionTimestampLimit;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the AIS track for the given vessel matching the given filter if any. If no time filter is provided the track of the last 24 hours are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {number} mmsi 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {string} [bwToken] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1AisAisTrack: async (mmsi: number, start?: string | null, end?: string | null, bwToken?: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mmsi' is not null or undefined
            assertParamExists('routesV1AisAisTrack', 'mmsi', mmsi)
            const localVarPath = `/v1.0/ais_track/{mmsi}`
                .replace(`{${"mmsi"}}`, encodeURIComponent(String(mmsi)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AisApi - functional programming interface
 * @export
 */
export const AisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AisApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all current AIS positions of vessels. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {string | null} [positionTimestampLimit] 
         * @param {string} [bwToken] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1AisAisCurrentPositions(positionTimestampLimit?: string | null, bwToken?: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrentAisPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1AisAisCurrentPositions(positionTimestampLimit, bwToken, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AisApi.routesV1AisAisCurrentPositions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the AIS track for the given vessel matching the given filter if any. If no time filter is provided the track of the last 24 hours are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {number} mmsi 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {string} [bwToken] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1AisAisTrack(mmsi: number, start?: string | null, end?: string | null, bwToken?: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AisPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1AisAisTrack(mmsi, start, end, bwToken, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AisApi.routesV1AisAisTrack']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AisApi - factory interface
 * @export
 */
export const AisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AisApiFp(configuration)
    return {
        /**
         * Returns all current AIS positions of vessels. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {AisApiRoutesV1AisAisCurrentPositionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1AisAisCurrentPositions(requestParameters: AisApiRoutesV1AisAisCurrentPositionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<CurrentAisPosition>> {
            return localVarFp.routesV1AisAisCurrentPositions(requestParameters.positionTimestampLimit, requestParameters.bwToken, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the AIS track for the given vessel matching the given filter if any. If no time filter is provided the track of the last 24 hours are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {AisApiRoutesV1AisAisTrackRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1AisAisTrack(requestParameters: AisApiRoutesV1AisAisTrackRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<AisPosition>> {
            return localVarFp.routesV1AisAisTrack(requestParameters.mmsi, requestParameters.start, requestParameters.end, requestParameters.bwToken, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1AisAisCurrentPositions operation in AisApi.
 * @export
 * @interface AisApiRoutesV1AisAisCurrentPositionsRequest
 */
export interface AisApiRoutesV1AisAisCurrentPositionsRequest {
    /**
     * 
     * @type {string}
     * @memberof AisApiRoutesV1AisAisCurrentPositions
     */
    readonly positionTimestampLimit?: string | null

    /**
     * 
     * @type {string}
     * @memberof AisApiRoutesV1AisAisCurrentPositions
     */
    readonly bwToken?: string

    /**
     * 
     * @type {string}
     * @memberof AisApiRoutesV1AisAisCurrentPositions
     */
    readonly authorization?: string
}

/**
 * Request parameters for routesV1AisAisTrack operation in AisApi.
 * @export
 * @interface AisApiRoutesV1AisAisTrackRequest
 */
export interface AisApiRoutesV1AisAisTrackRequest {
    /**
     * 
     * @type {number}
     * @memberof AisApiRoutesV1AisAisTrack
     */
    readonly mmsi: number

    /**
     * 
     * @type {string}
     * @memberof AisApiRoutesV1AisAisTrack
     */
    readonly start?: string | null

    /**
     * 
     * @type {string}
     * @memberof AisApiRoutesV1AisAisTrack
     */
    readonly end?: string | null

    /**
     * 
     * @type {string}
     * @memberof AisApiRoutesV1AisAisTrack
     */
    readonly bwToken?: string

    /**
     * 
     * @type {string}
     * @memberof AisApiRoutesV1AisAisTrack
     */
    readonly authorization?: string
}

/**
 * AisApi - object-oriented interface
 * @export
 * @class AisApi
 * @extends {BaseAPI}
 */
export class AisApi extends BaseAPI {
    /**
     * Returns all current AIS positions of vessels. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
     * @param {AisApiRoutesV1AisAisCurrentPositionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AisApi
     */
    public routesV1AisAisCurrentPositions(requestParameters: AisApiRoutesV1AisAisCurrentPositionsRequest = {}, options?: RawAxiosRequestConfig) {
        return AisApiFp(this.configuration).routesV1AisAisCurrentPositions(requestParameters.positionTimestampLimit, requestParameters.bwToken, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the AIS track for the given vessel matching the given filter if any. If no time filter is provided the track of the last 24 hours are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
     * @param {AisApiRoutesV1AisAisTrackRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AisApi
     */
    public routesV1AisAisTrack(requestParameters: AisApiRoutesV1AisAisTrackRequest, options?: RawAxiosRequestConfig) {
        return AisApiFp(this.configuration).routesV1AisAisTrack(requestParameters.mmsi, requestParameters.start, requestParameters.end, requestParameters.bwToken, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AisVmsApi - axios parameter creator
 * @export
 */
export const AisVmsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the combined AIS/VMS positions data for the given area. If no time filter is provided positions within the given area for the last 10 days are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {number} [x1] 
         * @param {number} [x2] 
         * @param {number} [y1] 
         * @param {number} [y2] 
         * @param {string | null} [dateLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1AisVmsAisVmsArea: async (x1?: number, x2?: number, y1?: number, y2?: number, dateLimit?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/ais_vms_area`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (x1 !== undefined) {
                localVarQueryParameter['x1'] = x1;
            }

            if (x2 !== undefined) {
                localVarQueryParameter['x2'] = x2;
            }

            if (y1 !== undefined) {
                localVarQueryParameter['y1'] = y1;
            }

            if (y2 !== undefined) {
                localVarQueryParameter['y2'] = y2;
            }

            if (dateLimit !== undefined) {
                localVarQueryParameter['dateLimit'] = (dateLimit as any instanceof Date) ?
                    (dateLimit as any).toISOString().substring(0,10) :
                    dateLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the combined AIS/VMS track for the given vessel matching the given filter if any. If no time filter is provided the track of the last 24 hours are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {number | null} [mmsi] 
         * @param {string | null} [callSign] 
         * @param {number | null} [tripId] 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {string} [bwToken] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1AisVmsAisVmsPositions: async (mmsi?: number | null, callSign?: string | null, tripId?: number | null, start?: string | null, end?: string | null, bwToken?: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/ais_vms_positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (mmsi !== undefined) {
                localVarQueryParameter['mmsi'] = mmsi;
            }

            if (callSign !== undefined) {
                localVarQueryParameter['callSign'] = callSign;
            }

            if (tripId !== undefined) {
                localVarQueryParameter['tripId'] = tripId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AisVmsApi - functional programming interface
 * @export
 */
export const AisVmsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AisVmsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the combined AIS/VMS positions data for the given area. If no time filter is provided positions within the given area for the last 10 days are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {number} [x1] 
         * @param {number} [x2] 
         * @param {number} [y1] 
         * @param {number} [y2] 
         * @param {string | null} [dateLimit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1AisVmsAisVmsArea(x1?: number, x2?: number, y1?: number, y2?: number, dateLimit?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AisVmsArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1AisVmsAisVmsArea(x1, x2, y1, y2, dateLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AisVmsApi.routesV1AisVmsAisVmsArea']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the combined AIS/VMS track for the given vessel matching the given filter if any. If no time filter is provided the track of the last 24 hours are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {number | null} [mmsi] 
         * @param {string | null} [callSign] 
         * @param {number | null} [tripId] 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {string} [bwToken] 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1AisVmsAisVmsPositions(mmsi?: number | null, callSign?: string | null, tripId?: number | null, start?: string | null, end?: string | null, bwToken?: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AisVmsPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1AisVmsAisVmsPositions(mmsi, callSign, tripId, start, end, bwToken, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AisVmsApi.routesV1AisVmsAisVmsPositions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AisVmsApi - factory interface
 * @export
 */
export const AisVmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AisVmsApiFp(configuration)
    return {
        /**
         * Returns the combined AIS/VMS positions data for the given area. If no time filter is provided positions within the given area for the last 10 days are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {AisVmsApiRoutesV1AisVmsAisVmsAreaRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1AisVmsAisVmsArea(requestParameters: AisVmsApiRoutesV1AisVmsAisVmsAreaRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AisVmsArea> {
            return localVarFp.routesV1AisVmsAisVmsArea(requestParameters.x1, requestParameters.x2, requestParameters.y1, requestParameters.y2, requestParameters.dateLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the combined AIS/VMS track for the given vessel matching the given filter if any. If no time filter is provided the track of the last 24 hours are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
         * @param {AisVmsApiRoutesV1AisVmsAisVmsPositionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1AisVmsAisVmsPositions(requestParameters: AisVmsApiRoutesV1AisVmsAisVmsPositionsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<AisVmsPosition>> {
            return localVarFp.routesV1AisVmsAisVmsPositions(requestParameters.mmsi, requestParameters.callSign, requestParameters.tripId, requestParameters.start, requestParameters.end, requestParameters.bwToken, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1AisVmsAisVmsArea operation in AisVmsApi.
 * @export
 * @interface AisVmsApiRoutesV1AisVmsAisVmsAreaRequest
 */
export interface AisVmsApiRoutesV1AisVmsAisVmsAreaRequest {
    /**
     * 
     * @type {number}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsArea
     */
    readonly x1?: number

    /**
     * 
     * @type {number}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsArea
     */
    readonly x2?: number

    /**
     * 
     * @type {number}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsArea
     */
    readonly y1?: number

    /**
     * 
     * @type {number}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsArea
     */
    readonly y2?: number

    /**
     * 
     * @type {string}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsArea
     */
    readonly dateLimit?: string | null
}

/**
 * Request parameters for routesV1AisVmsAisVmsPositions operation in AisVmsApi.
 * @export
 * @interface AisVmsApiRoutesV1AisVmsAisVmsPositionsRequest
 */
export interface AisVmsApiRoutesV1AisVmsAisVmsPositionsRequest {
    /**
     * 
     * @type {number}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsPositions
     */
    readonly mmsi?: number | null

    /**
     * 
     * @type {string}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsPositions
     */
    readonly callSign?: string | null

    /**
     * 
     * @type {number}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsPositions
     */
    readonly tripId?: number | null

    /**
     * 
     * @type {string}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsPositions
     */
    readonly start?: string | null

    /**
     * 
     * @type {string}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsPositions
     */
    readonly end?: string | null

    /**
     * 
     * @type {string}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsPositions
     */
    readonly bwToken?: string

    /**
     * 
     * @type {string}
     * @memberof AisVmsApiRoutesV1AisVmsAisVmsPositions
     */
    readonly authorization?: string
}

/**
 * AisVmsApi - object-oriented interface
 * @export
 * @class AisVmsApi
 * @extends {BaseAPI}
 */
export class AisVmsApi extends BaseAPI {
    /**
     * Returns the combined AIS/VMS positions data for the given area. If no time filter is provided positions within the given area for the last 10 days are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
     * @param {AisVmsApiRoutesV1AisVmsAisVmsAreaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AisVmsApi
     */
    public routesV1AisVmsAisVmsArea(requestParameters: AisVmsApiRoutesV1AisVmsAisVmsAreaRequest = {}, options?: RawAxiosRequestConfig) {
        return AisVmsApiFp(this.configuration).routesV1AisVmsAisVmsArea(requestParameters.x1, requestParameters.x2, requestParameters.y1, requestParameters.y2, requestParameters.dateLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the combined AIS/VMS track for the given vessel matching the given filter if any. If no time filter is provided the track of the last 24 hours are returned. AIS data for vessels under 15m are restricted to authenticated users with sufficient permissions.
     * @param {AisVmsApiRoutesV1AisVmsAisVmsPositionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AisVmsApi
     */
    public routesV1AisVmsAisVmsPositions(requestParameters: AisVmsApiRoutesV1AisVmsAisVmsPositionsRequest = {}, options?: RawAxiosRequestConfig) {
        return AisVmsApiFp(this.configuration).routesV1AisVmsAisVmsPositions(requestParameters.mmsi, requestParameters.callSign, requestParameters.tripId, requestParameters.start, requestParameters.end, requestParameters.bwToken, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeliveryPointApi - axios parameter creator
 * @export
 */
export const DeliveryPointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all known delivery points. Delivery points originates from the following sources: - Buyer register from Fiskeridirektoratet - Aqua culture register from Fiskeridirektoratet - Mattilsynet approval lists - Manual entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1DeliveryPointDeliveryPoints: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/delivery_points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliveryPointApi - functional programming interface
 * @export
 */
export const DeliveryPointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliveryPointApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all known delivery points. Delivery points originates from the following sources: - Buyer register from Fiskeridirektoratet - Aqua culture register from Fiskeridirektoratet - Mattilsynet approval lists - Manual entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1DeliveryPointDeliveryPoints(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeliveryPoint>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1DeliveryPointDeliveryPoints(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliveryPointApi.routesV1DeliveryPointDeliveryPoints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeliveryPointApi - factory interface
 * @export
 */
export const DeliveryPointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliveryPointApiFp(configuration)
    return {
        /**
         * Returns all known delivery points. Delivery points originates from the following sources: - Buyer register from Fiskeridirektoratet - Aqua culture register from Fiskeridirektoratet - Mattilsynet approval lists - Manual entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1DeliveryPointDeliveryPoints(options?: RawAxiosRequestConfig): AxiosPromise<Array<DeliveryPoint>> {
            return localVarFp.routesV1DeliveryPointDeliveryPoints(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliveryPointApi - object-oriented interface
 * @export
 * @class DeliveryPointApi
 * @extends {BaseAPI}
 */
export class DeliveryPointApi extends BaseAPI {
    /**
     * Returns all known delivery points. Delivery points originates from the following sources: - Buyer register from Fiskeridirektoratet - Aqua culture register from Fiskeridirektoratet - Mattilsynet approval lists - Manual entries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliveryPointApi
     */
    public routesV1DeliveryPointDeliveryPoints(options?: RawAxiosRequestConfig) {
        return DeliveryPointApiFp(this.configuration).routesV1DeliveryPointDeliveryPoints(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FishingFacilityApi - axios parameter creator
 * @export
 */
export const FishingFacilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all fishing facilities matching the provided parameters. Access to fishing facilities are limited to authenticated users with sufficient permissions.
         * @param {string} bwToken 
         * @param {Array<number> | null} [mmsis] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {Array<FishingFacilityToolType> | null} [toolTypes] 
         * @param {boolean | null} [active] 
         * @param {Array<string> | null} [setupRanges] 
         * @param {Array<string> | null} [removedRanges] 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {Ordering | null} [ordering] 
         * @param {FishingFacilitiesSorting | null} [sorting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FishingFacilityFishingFacilities: async (bwToken: string, mmsis?: Array<number> | null, fiskeridirVesselIds?: Array<number> | null, toolTypes?: Array<FishingFacilityToolType> | null, active?: boolean | null, setupRanges?: Array<string> | null, removedRanges?: Array<string> | null, limit?: number | null, offset?: number | null, ordering?: Ordering | null, sorting?: FishingFacilitiesSorting | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1FishingFacilityFishingFacilities', 'bwToken', bwToken)
            const localVarPath = `/v1.0/fishing_facilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (mmsis) {
                localVarQueryParameter['mmsis[]'] = mmsis;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }

            if (toolTypes) {
                localVarQueryParameter['toolTypes[]'] = toolTypes;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (setupRanges) {
                localVarQueryParameter['setupRanges[]'] = setupRanges;
            }

            if (removedRanges) {
                localVarQueryParameter['removedRanges[]'] = removedRanges;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FishingFacilityApi - functional programming interface
 * @export
 */
export const FishingFacilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FishingFacilityApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all fishing facilities matching the provided parameters. Access to fishing facilities are limited to authenticated users with sufficient permissions.
         * @param {string} bwToken 
         * @param {Array<number> | null} [mmsis] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {Array<FishingFacilityToolType> | null} [toolTypes] 
         * @param {boolean | null} [active] 
         * @param {Array<string> | null} [setupRanges] 
         * @param {Array<string> | null} [removedRanges] 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {Ordering | null} [ordering] 
         * @param {FishingFacilitiesSorting | null} [sorting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1FishingFacilityFishingFacilities(bwToken: string, mmsis?: Array<number> | null, fiskeridirVesselIds?: Array<number> | null, toolTypes?: Array<FishingFacilityToolType> | null, active?: boolean | null, setupRanges?: Array<string> | null, removedRanges?: Array<string> | null, limit?: number | null, offset?: number | null, ordering?: Ordering | null, sorting?: FishingFacilitiesSorting | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FishingFacility>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1FishingFacilityFishingFacilities(bwToken, mmsis, fiskeridirVesselIds, toolTypes, active, setupRanges, removedRanges, limit, offset, ordering, sorting, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FishingFacilityApi.routesV1FishingFacilityFishingFacilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FishingFacilityApi - factory interface
 * @export
 */
export const FishingFacilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FishingFacilityApiFp(configuration)
    return {
        /**
         * Returns all fishing facilities matching the provided parameters. Access to fishing facilities are limited to authenticated users with sufficient permissions.
         * @param {FishingFacilityApiRoutesV1FishingFacilityFishingFacilitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FishingFacilityFishingFacilities(requestParameters: FishingFacilityApiRoutesV1FishingFacilityFishingFacilitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FishingFacility>> {
            return localVarFp.routesV1FishingFacilityFishingFacilities(requestParameters.bwToken, requestParameters.mmsis, requestParameters.fiskeridirVesselIds, requestParameters.toolTypes, requestParameters.active, requestParameters.setupRanges, requestParameters.removedRanges, requestParameters.limit, requestParameters.offset, requestParameters.ordering, requestParameters.sorting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1FishingFacilityFishingFacilities operation in FishingFacilityApi.
 * @export
 * @interface FishingFacilityApiRoutesV1FishingFacilityFishingFacilitiesRequest
 */
export interface FishingFacilityApiRoutesV1FishingFacilityFishingFacilitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly bwToken: string

    /**
     * 
     * @type {Array<number>}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly mmsis?: Array<number> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly fiskeridirVesselIds?: Array<number> | null

    /**
     * 
     * @type {Array<FishingFacilityToolType>}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly toolTypes?: Array<FishingFacilityToolType> | null

    /**
     * 
     * @type {boolean}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly active?: boolean | null

    /**
     * 
     * @type {Array<string>}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly setupRanges?: Array<string> | null

    /**
     * 
     * @type {Array<string>}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly removedRanges?: Array<string> | null

    /**
     * 
     * @type {number}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly offset?: number | null

    /**
     * 
     * @type {Ordering}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly ordering?: Ordering | null

    /**
     * 
     * @type {FishingFacilitiesSorting}
     * @memberof FishingFacilityApiRoutesV1FishingFacilityFishingFacilities
     */
    readonly sorting?: FishingFacilitiesSorting | null
}

/**
 * FishingFacilityApi - object-oriented interface
 * @export
 * @class FishingFacilityApi
 * @extends {BaseAPI}
 */
export class FishingFacilityApi extends BaseAPI {
    /**
     * Returns all fishing facilities matching the provided parameters. Access to fishing facilities are limited to authenticated users with sufficient permissions.
     * @param {FishingFacilityApiRoutesV1FishingFacilityFishingFacilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FishingFacilityApi
     */
    public routesV1FishingFacilityFishingFacilities(requestParameters: FishingFacilityApiRoutesV1FishingFacilityFishingFacilitiesRequest, options?: RawAxiosRequestConfig) {
        return FishingFacilityApiFp(this.configuration).routesV1FishingFacilityFishingFacilities(requestParameters.bwToken, requestParameters.mmsis, requestParameters.fiskeridirVesselIds, requestParameters.toolTypes, requestParameters.active, requestParameters.setupRanges, requestParameters.removedRanges, requestParameters.limit, requestParameters.offset, requestParameters.ordering, requestParameters.sorting, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FishingPredictionApi - axios parameter creator
 * @export
 */
export const FishingPredictionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ModelId} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FishingPredictionAllFishingSpotPredictions: async (modelId: ModelId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('routesV1FishingPredictionAllFishingSpotPredictions', 'modelId', modelId)
            const localVarPath = `/v1.0/fishing_spot_predictions/{model_id}`
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FishingPredictionAllFishingWeightPredictions: async (modelId: ModelId, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('routesV1FishingPredictionAllFishingWeightPredictions', 'modelId', modelId)
            const localVarPath = `/v1.0/fishing_weight_predictions/{model_id}`
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {SpeciesGroup} speciesGroupId 
         * @param {string | null} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FishingPredictionFishingSpotPredictions: async (modelId: ModelId, speciesGroupId: SpeciesGroup, date?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('routesV1FishingPredictionFishingSpotPredictions', 'modelId', modelId)
            // verify required parameter 'speciesGroupId' is not null or undefined
            assertParamExists('routesV1FishingPredictionFishingSpotPredictions', 'speciesGroupId', speciesGroupId)
            const localVarPath = `/v1.0/fishing_spot_predictions/{model_id}/{species_group_id}`
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"species_group_id"}}`, encodeURIComponent(String(speciesGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substring(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {SpeciesGroup} speciesGroupId 
         * @param {string | null} [date] 
         * @param {number | null} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FishingPredictionFishingWeightPredictions: async (modelId: ModelId, speciesGroupId: SpeciesGroup, date?: string | null, limit?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('routesV1FishingPredictionFishingWeightPredictions', 'modelId', modelId)
            // verify required parameter 'speciesGroupId' is not null or undefined
            assertParamExists('routesV1FishingPredictionFishingWeightPredictions', 'speciesGroupId', speciesGroupId)
            const localVarPath = `/v1.0/fishing_weight_predictions/{model_id}/{species_group_id}`
                .replace(`{${"model_id"}}`, encodeURIComponent(String(modelId)))
                .replace(`{${"species_group_id"}}`, encodeURIComponent(String(speciesGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substring(0,10) :
                    date;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FishingPredictionApi - functional programming interface
 * @export
 */
export const FishingPredictionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FishingPredictionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ModelId} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1FishingPredictionAllFishingSpotPredictions(modelId: ModelId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FishingSpotPrediction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1FishingPredictionAllFishingSpotPredictions(modelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FishingPredictionApi.routesV1FishingPredictionAllFishingSpotPredictions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1FishingPredictionAllFishingWeightPredictions(modelId: ModelId, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FishingWeightPrediction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1FishingPredictionAllFishingWeightPredictions(modelId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FishingPredictionApi.routesV1FishingPredictionAllFishingWeightPredictions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {SpeciesGroup} speciesGroupId 
         * @param {string | null} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1FishingPredictionFishingSpotPredictions(modelId: ModelId, speciesGroupId: SpeciesGroup, date?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FishingSpotPrediction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1FishingPredictionFishingSpotPredictions(modelId, speciesGroupId, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FishingPredictionApi.routesV1FishingPredictionFishingSpotPredictions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ModelId} modelId 
         * @param {SpeciesGroup} speciesGroupId 
         * @param {string | null} [date] 
         * @param {number | null} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1FishingPredictionFishingWeightPredictions(modelId: ModelId, speciesGroupId: SpeciesGroup, date?: string | null, limit?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FishingWeightPrediction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1FishingPredictionFishingWeightPredictions(modelId, speciesGroupId, date, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FishingPredictionApi.routesV1FishingPredictionFishingWeightPredictions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FishingPredictionApi - factory interface
 * @export
 */
export const FishingPredictionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FishingPredictionApiFp(configuration)
    return {
        /**
         * 
         * @param {FishingPredictionApiRoutesV1FishingPredictionAllFishingSpotPredictionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FishingPredictionAllFishingSpotPredictions(requestParameters: FishingPredictionApiRoutesV1FishingPredictionAllFishingSpotPredictionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FishingSpotPrediction>> {
            return localVarFp.routesV1FishingPredictionAllFishingSpotPredictions(requestParameters.modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FishingPredictionApiRoutesV1FishingPredictionAllFishingWeightPredictionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FishingPredictionAllFishingWeightPredictions(requestParameters: FishingPredictionApiRoutesV1FishingPredictionAllFishingWeightPredictionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FishingWeightPrediction>> {
            return localVarFp.routesV1FishingPredictionAllFishingWeightPredictions(requestParameters.modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FishingPredictionApiRoutesV1FishingPredictionFishingSpotPredictionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FishingPredictionFishingSpotPredictions(requestParameters: FishingPredictionApiRoutesV1FishingPredictionFishingSpotPredictionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<FishingSpotPrediction> {
            return localVarFp.routesV1FishingPredictionFishingSpotPredictions(requestParameters.modelId, requestParameters.speciesGroupId, requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FishingPredictionApiRoutesV1FishingPredictionFishingWeightPredictionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FishingPredictionFishingWeightPredictions(requestParameters: FishingPredictionApiRoutesV1FishingPredictionFishingWeightPredictionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FishingWeightPrediction>> {
            return localVarFp.routesV1FishingPredictionFishingWeightPredictions(requestParameters.modelId, requestParameters.speciesGroupId, requestParameters.date, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1FishingPredictionAllFishingSpotPredictions operation in FishingPredictionApi.
 * @export
 * @interface FishingPredictionApiRoutesV1FishingPredictionAllFishingSpotPredictionsRequest
 */
export interface FishingPredictionApiRoutesV1FishingPredictionAllFishingSpotPredictionsRequest {
    /**
     * 
     * @type {ModelId}
     * @memberof FishingPredictionApiRoutesV1FishingPredictionAllFishingSpotPredictions
     */
    readonly modelId: ModelId
}

/**
 * Request parameters for routesV1FishingPredictionAllFishingWeightPredictions operation in FishingPredictionApi.
 * @export
 * @interface FishingPredictionApiRoutesV1FishingPredictionAllFishingWeightPredictionsRequest
 */
export interface FishingPredictionApiRoutesV1FishingPredictionAllFishingWeightPredictionsRequest {
    /**
     * 
     * @type {ModelId}
     * @memberof FishingPredictionApiRoutesV1FishingPredictionAllFishingWeightPredictions
     */
    readonly modelId: ModelId
}

/**
 * Request parameters for routesV1FishingPredictionFishingSpotPredictions operation in FishingPredictionApi.
 * @export
 * @interface FishingPredictionApiRoutesV1FishingPredictionFishingSpotPredictionsRequest
 */
export interface FishingPredictionApiRoutesV1FishingPredictionFishingSpotPredictionsRequest {
    /**
     * 
     * @type {ModelId}
     * @memberof FishingPredictionApiRoutesV1FishingPredictionFishingSpotPredictions
     */
    readonly modelId: ModelId

    /**
     * 
     * @type {SpeciesGroup}
     * @memberof FishingPredictionApiRoutesV1FishingPredictionFishingSpotPredictions
     */
    readonly speciesGroupId: SpeciesGroup

    /**
     * 
     * @type {string}
     * @memberof FishingPredictionApiRoutesV1FishingPredictionFishingSpotPredictions
     */
    readonly date?: string | null
}

/**
 * Request parameters for routesV1FishingPredictionFishingWeightPredictions operation in FishingPredictionApi.
 * @export
 * @interface FishingPredictionApiRoutesV1FishingPredictionFishingWeightPredictionsRequest
 */
export interface FishingPredictionApiRoutesV1FishingPredictionFishingWeightPredictionsRequest {
    /**
     * 
     * @type {ModelId}
     * @memberof FishingPredictionApiRoutesV1FishingPredictionFishingWeightPredictions
     */
    readonly modelId: ModelId

    /**
     * 
     * @type {SpeciesGroup}
     * @memberof FishingPredictionApiRoutesV1FishingPredictionFishingWeightPredictions
     */
    readonly speciesGroupId: SpeciesGroup

    /**
     * 
     * @type {string}
     * @memberof FishingPredictionApiRoutesV1FishingPredictionFishingWeightPredictions
     */
    readonly date?: string | null

    /**
     * 
     * @type {number}
     * @memberof FishingPredictionApiRoutesV1FishingPredictionFishingWeightPredictions
     */
    readonly limit?: number | null
}

/**
 * FishingPredictionApi - object-oriented interface
 * @export
 * @class FishingPredictionApi
 * @extends {BaseAPI}
 */
export class FishingPredictionApi extends BaseAPI {
    /**
     * 
     * @param {FishingPredictionApiRoutesV1FishingPredictionAllFishingSpotPredictionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FishingPredictionApi
     */
    public routesV1FishingPredictionAllFishingSpotPredictions(requestParameters: FishingPredictionApiRoutesV1FishingPredictionAllFishingSpotPredictionsRequest, options?: RawAxiosRequestConfig) {
        return FishingPredictionApiFp(this.configuration).routesV1FishingPredictionAllFishingSpotPredictions(requestParameters.modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FishingPredictionApiRoutesV1FishingPredictionAllFishingWeightPredictionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FishingPredictionApi
     */
    public routesV1FishingPredictionAllFishingWeightPredictions(requestParameters: FishingPredictionApiRoutesV1FishingPredictionAllFishingWeightPredictionsRequest, options?: RawAxiosRequestConfig) {
        return FishingPredictionApiFp(this.configuration).routesV1FishingPredictionAllFishingWeightPredictions(requestParameters.modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FishingPredictionApiRoutesV1FishingPredictionFishingSpotPredictionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FishingPredictionApi
     */
    public routesV1FishingPredictionFishingSpotPredictions(requestParameters: FishingPredictionApiRoutesV1FishingPredictionFishingSpotPredictionsRequest, options?: RawAxiosRequestConfig) {
        return FishingPredictionApiFp(this.configuration).routesV1FishingPredictionFishingSpotPredictions(requestParameters.modelId, requestParameters.speciesGroupId, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FishingPredictionApiRoutesV1FishingPredictionFishingWeightPredictionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FishingPredictionApi
     */
    public routesV1FishingPredictionFishingWeightPredictions(requestParameters: FishingPredictionApiRoutesV1FishingPredictionFishingWeightPredictionsRequest, options?: RawAxiosRequestConfig) {
        return FishingPredictionApiFp(this.configuration).routesV1FishingPredictionFishingWeightPredictions(requestParameters.modelId, requestParameters.speciesGroupId, requestParameters.date, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FuelMeasurementApi - axios parameter creator
 * @export
 */
export const FuelMeasurementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} bwToken 
         * @param {Array<FuelMeasurementBody>} fuelMeasurementBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FuelMeasurementCreateFuelMeasurements: async (bwToken: string, fuelMeasurementBody: Array<FuelMeasurementBody>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1FuelMeasurementCreateFuelMeasurements', 'bwToken', bwToken)
            // verify required parameter 'fuelMeasurementBody' is not null or undefined
            assertParamExists('routesV1FuelMeasurementCreateFuelMeasurements', 'fuelMeasurementBody', fuelMeasurementBody)
            const localVarPath = `/v1.0/fuel_measurements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fuelMeasurementBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bwToken 
         * @param {Array<DeleteFuelMeasurement>} deleteFuelMeasurement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FuelMeasurementDeleteFuelMeasurements: async (bwToken: string, deleteFuelMeasurement: Array<DeleteFuelMeasurement>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1FuelMeasurementDeleteFuelMeasurements', 'bwToken', bwToken)
            // verify required parameter 'deleteFuelMeasurement' is not null or undefined
            assertParamExists('routesV1FuelMeasurementDeleteFuelMeasurements', 'deleteFuelMeasurement', deleteFuelMeasurement)
            const localVarPath = `/v1.0/fuel_measurements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFuelMeasurement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bwToken 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FuelMeasurementGetFuelMeasurements: async (bwToken: string, startDate?: string | null, endDate?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1FuelMeasurementGetFuelMeasurements', 'bwToken', bwToken)
            const localVarPath = `/v1.0/fuel_measurements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bwToken 
         * @param {Array<FuelMeasurementBody>} fuelMeasurementBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FuelMeasurementUpdateFuelMeasurements: async (bwToken: string, fuelMeasurementBody: Array<FuelMeasurementBody>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1FuelMeasurementUpdateFuelMeasurements', 'bwToken', bwToken)
            // verify required parameter 'fuelMeasurementBody' is not null or undefined
            assertParamExists('routesV1FuelMeasurementUpdateFuelMeasurements', 'fuelMeasurementBody', fuelMeasurementBody)
            const localVarPath = `/v1.0/fuel_measurements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fuelMeasurementBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FuelMeasurementApi - functional programming interface
 * @export
 */
export const FuelMeasurementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FuelMeasurementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} bwToken 
         * @param {Array<FuelMeasurementBody>} fuelMeasurementBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1FuelMeasurementCreateFuelMeasurements(bwToken: string, fuelMeasurementBody: Array<FuelMeasurementBody>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1FuelMeasurementCreateFuelMeasurements(bwToken, fuelMeasurementBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FuelMeasurementApi.routesV1FuelMeasurementCreateFuelMeasurements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} bwToken 
         * @param {Array<DeleteFuelMeasurement>} deleteFuelMeasurement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1FuelMeasurementDeleteFuelMeasurements(bwToken: string, deleteFuelMeasurement: Array<DeleteFuelMeasurement>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1FuelMeasurementDeleteFuelMeasurements(bwToken, deleteFuelMeasurement, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FuelMeasurementApi.routesV1FuelMeasurementDeleteFuelMeasurements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} bwToken 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1FuelMeasurementGetFuelMeasurements(bwToken: string, startDate?: string | null, endDate?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FuelMeasurement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1FuelMeasurementGetFuelMeasurements(bwToken, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FuelMeasurementApi.routesV1FuelMeasurementGetFuelMeasurements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} bwToken 
         * @param {Array<FuelMeasurementBody>} fuelMeasurementBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1FuelMeasurementUpdateFuelMeasurements(bwToken: string, fuelMeasurementBody: Array<FuelMeasurementBody>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1FuelMeasurementUpdateFuelMeasurements(bwToken, fuelMeasurementBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FuelMeasurementApi.routesV1FuelMeasurementUpdateFuelMeasurements']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FuelMeasurementApi - factory interface
 * @export
 */
export const FuelMeasurementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FuelMeasurementApiFp(configuration)
    return {
        /**
         * 
         * @param {FuelMeasurementApiRoutesV1FuelMeasurementCreateFuelMeasurementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FuelMeasurementCreateFuelMeasurements(requestParameters: FuelMeasurementApiRoutesV1FuelMeasurementCreateFuelMeasurementsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.routesV1FuelMeasurementCreateFuelMeasurements(requestParameters.bwToken, requestParameters.fuelMeasurementBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FuelMeasurementApiRoutesV1FuelMeasurementDeleteFuelMeasurementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FuelMeasurementDeleteFuelMeasurements(requestParameters: FuelMeasurementApiRoutesV1FuelMeasurementDeleteFuelMeasurementsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.routesV1FuelMeasurementDeleteFuelMeasurements(requestParameters.bwToken, requestParameters.deleteFuelMeasurement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FuelMeasurementApiRoutesV1FuelMeasurementGetFuelMeasurementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FuelMeasurementGetFuelMeasurements(requestParameters: FuelMeasurementApiRoutesV1FuelMeasurementGetFuelMeasurementsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FuelMeasurement>> {
            return localVarFp.routesV1FuelMeasurementGetFuelMeasurements(requestParameters.bwToken, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FuelMeasurementApiRoutesV1FuelMeasurementUpdateFuelMeasurementsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1FuelMeasurementUpdateFuelMeasurements(requestParameters: FuelMeasurementApiRoutesV1FuelMeasurementUpdateFuelMeasurementsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.routesV1FuelMeasurementUpdateFuelMeasurements(requestParameters.bwToken, requestParameters.fuelMeasurementBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1FuelMeasurementCreateFuelMeasurements operation in FuelMeasurementApi.
 * @export
 * @interface FuelMeasurementApiRoutesV1FuelMeasurementCreateFuelMeasurementsRequest
 */
export interface FuelMeasurementApiRoutesV1FuelMeasurementCreateFuelMeasurementsRequest {
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurementApiRoutesV1FuelMeasurementCreateFuelMeasurements
     */
    readonly bwToken: string

    /**
     * 
     * @type {Array<FuelMeasurementBody>}
     * @memberof FuelMeasurementApiRoutesV1FuelMeasurementCreateFuelMeasurements
     */
    readonly fuelMeasurementBody: Array<FuelMeasurementBody>
}

/**
 * Request parameters for routesV1FuelMeasurementDeleteFuelMeasurements operation in FuelMeasurementApi.
 * @export
 * @interface FuelMeasurementApiRoutesV1FuelMeasurementDeleteFuelMeasurementsRequest
 */
export interface FuelMeasurementApiRoutesV1FuelMeasurementDeleteFuelMeasurementsRequest {
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurementApiRoutesV1FuelMeasurementDeleteFuelMeasurements
     */
    readonly bwToken: string

    /**
     * 
     * @type {Array<DeleteFuelMeasurement>}
     * @memberof FuelMeasurementApiRoutesV1FuelMeasurementDeleteFuelMeasurements
     */
    readonly deleteFuelMeasurement: Array<DeleteFuelMeasurement>
}

/**
 * Request parameters for routesV1FuelMeasurementGetFuelMeasurements operation in FuelMeasurementApi.
 * @export
 * @interface FuelMeasurementApiRoutesV1FuelMeasurementGetFuelMeasurementsRequest
 */
export interface FuelMeasurementApiRoutesV1FuelMeasurementGetFuelMeasurementsRequest {
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurementApiRoutesV1FuelMeasurementGetFuelMeasurements
     */
    readonly bwToken: string

    /**
     * 
     * @type {string}
     * @memberof FuelMeasurementApiRoutesV1FuelMeasurementGetFuelMeasurements
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof FuelMeasurementApiRoutesV1FuelMeasurementGetFuelMeasurements
     */
    readonly endDate?: string | null
}

/**
 * Request parameters for routesV1FuelMeasurementUpdateFuelMeasurements operation in FuelMeasurementApi.
 * @export
 * @interface FuelMeasurementApiRoutesV1FuelMeasurementUpdateFuelMeasurementsRequest
 */
export interface FuelMeasurementApiRoutesV1FuelMeasurementUpdateFuelMeasurementsRequest {
    /**
     * 
     * @type {string}
     * @memberof FuelMeasurementApiRoutesV1FuelMeasurementUpdateFuelMeasurements
     */
    readonly bwToken: string

    /**
     * 
     * @type {Array<FuelMeasurementBody>}
     * @memberof FuelMeasurementApiRoutesV1FuelMeasurementUpdateFuelMeasurements
     */
    readonly fuelMeasurementBody: Array<FuelMeasurementBody>
}

/**
 * FuelMeasurementApi - object-oriented interface
 * @export
 * @class FuelMeasurementApi
 * @extends {BaseAPI}
 */
export class FuelMeasurementApi extends BaseAPI {
    /**
     * 
     * @param {FuelMeasurementApiRoutesV1FuelMeasurementCreateFuelMeasurementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuelMeasurementApi
     */
    public routesV1FuelMeasurementCreateFuelMeasurements(requestParameters: FuelMeasurementApiRoutesV1FuelMeasurementCreateFuelMeasurementsRequest, options?: RawAxiosRequestConfig) {
        return FuelMeasurementApiFp(this.configuration).routesV1FuelMeasurementCreateFuelMeasurements(requestParameters.bwToken, requestParameters.fuelMeasurementBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FuelMeasurementApiRoutesV1FuelMeasurementDeleteFuelMeasurementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuelMeasurementApi
     */
    public routesV1FuelMeasurementDeleteFuelMeasurements(requestParameters: FuelMeasurementApiRoutesV1FuelMeasurementDeleteFuelMeasurementsRequest, options?: RawAxiosRequestConfig) {
        return FuelMeasurementApiFp(this.configuration).routesV1FuelMeasurementDeleteFuelMeasurements(requestParameters.bwToken, requestParameters.deleteFuelMeasurement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FuelMeasurementApiRoutesV1FuelMeasurementGetFuelMeasurementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuelMeasurementApi
     */
    public routesV1FuelMeasurementGetFuelMeasurements(requestParameters: FuelMeasurementApiRoutesV1FuelMeasurementGetFuelMeasurementsRequest, options?: RawAxiosRequestConfig) {
        return FuelMeasurementApiFp(this.configuration).routesV1FuelMeasurementGetFuelMeasurements(requestParameters.bwToken, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FuelMeasurementApiRoutesV1FuelMeasurementUpdateFuelMeasurementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FuelMeasurementApi
     */
    public routesV1FuelMeasurementUpdateFuelMeasurements(requestParameters: FuelMeasurementApiRoutesV1FuelMeasurementUpdateFuelMeasurementsRequest, options?: RawAxiosRequestConfig) {
        return FuelMeasurementApiFp(this.configuration).routesV1FuelMeasurementUpdateFuelMeasurements(requestParameters.bwToken, requestParameters.fuelMeasurementBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GearApi - axios parameter creator
 * @export
 */
export const GearApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1GearGear: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/gear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1GearGearGroups: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/gear_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1GearGearMainGroups: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/gear_main_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GearApi - functional programming interface
 * @export
 */
export const GearApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GearApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1GearGear(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GearDetailed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1GearGear(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GearApi.routesV1GearGear']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1GearGearGroups(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GearGroupDetailed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1GearGearGroups(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GearApi.routesV1GearGearGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1GearGearMainGroups(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GearMainGroupDetailed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1GearGearMainGroups(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GearApi.routesV1GearGearMainGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GearApi - factory interface
 * @export
 */
export const GearApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GearApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1GearGear(options?: RawAxiosRequestConfig): AxiosPromise<Array<GearDetailed>> {
            return localVarFp.routesV1GearGear(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1GearGearGroups(options?: RawAxiosRequestConfig): AxiosPromise<Array<GearGroupDetailed>> {
            return localVarFp.routesV1GearGearGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1GearGearMainGroups(options?: RawAxiosRequestConfig): AxiosPromise<Array<GearMainGroupDetailed>> {
            return localVarFp.routesV1GearGearMainGroups(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GearApi - object-oriented interface
 * @export
 * @class GearApi
 * @extends {BaseAPI}
 */
export class GearApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GearApi
     */
    public routesV1GearGear(options?: RawAxiosRequestConfig) {
        return GearApiFp(this.configuration).routesV1GearGear(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GearApi
     */
    public routesV1GearGearGroups(options?: RawAxiosRequestConfig) {
        return GearApiFp(this.configuration).routesV1GearGearGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GearApi
     */
    public routesV1GearGearMainGroups(options?: RawAxiosRequestConfig) {
        return GearApiFp(this.configuration).routesV1GearGearMainGroups(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HaulApi - axios parameter creator
 * @export
 */
export const HaulApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all hauls matching the provided parameters.
         * @param {Array<string> | null} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {HaulsSorting | null} [sorting] 
         * @param {Ordering | null} [ordering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1HaulHauls: async (months?: Array<string> | null, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, sorting?: HaulsSorting | null, ordering?: Ordering | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/hauls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (months) {
                localVarQueryParameter['months[]'] = months;
            }

            if (catchLocations) {
                localVarQueryParameter['catchLocations[]'] = catchLocations;
            }

            if (gearGroupIds) {
                localVarQueryParameter['gearGroupIds[]'] = gearGroupIds;
            }

            if (speciesGroupIds) {
                localVarQueryParameter['speciesGroupIds[]'] = speciesGroupIds;
            }

            if (vesselLengthGroups) {
                localVarQueryParameter['vesselLengthGroups[]'] = vesselLengthGroups;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns an aggregated matrix view of haul living weights.
         * @param {ActiveHaulsFilter} activeFilter 
         * @param {Array<number> | null} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {number | null} [bycatchPercentage] 
         * @param {boolean | null} [majoritySpeciesGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1HaulHaulsMatrix: async (activeFilter: ActiveHaulsFilter, months?: Array<number> | null, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, bycatchPercentage?: number | null, majoritySpeciesGroup?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeFilter' is not null or undefined
            assertParamExists('routesV1HaulHaulsMatrix', 'activeFilter', activeFilter)
            const localVarPath = `/v1.0/hauls_matrix/{active_filter}`
                .replace(`{${"active_filter"}}`, encodeURIComponent(String(activeFilter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (months) {
                localVarQueryParameter['months[]'] = months;
            }

            if (catchLocations) {
                localVarQueryParameter['catchLocations[]'] = catchLocations;
            }

            if (gearGroupIds) {
                localVarQueryParameter['gearGroupIds[]'] = gearGroupIds;
            }

            if (speciesGroupIds) {
                localVarQueryParameter['speciesGroupIds[]'] = speciesGroupIds;
            }

            if (vesselLengthGroups) {
                localVarQueryParameter['vesselLengthGroups[]'] = vesselLengthGroups;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }

            if (bycatchPercentage !== undefined) {
                localVarQueryParameter['bycatchPercentage'] = bycatchPercentage;
            }

            if (majoritySpeciesGroup !== undefined) {
                localVarQueryParameter['majoritySpeciesGroup'] = majoritySpeciesGroup;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HaulApi - functional programming interface
 * @export
 */
export const HaulApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HaulApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all hauls matching the provided parameters.
         * @param {Array<string> | null} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {HaulsSorting | null} [sorting] 
         * @param {Ordering | null} [ordering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1HaulHauls(months?: Array<string> | null, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, sorting?: HaulsSorting | null, ordering?: Ordering | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Haul>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1HaulHauls(months, catchLocations, gearGroupIds, speciesGroupIds, vesselLengthGroups, fiskeridirVesselIds, sorting, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HaulApi.routesV1HaulHauls']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns an aggregated matrix view of haul living weights.
         * @param {ActiveHaulsFilter} activeFilter 
         * @param {Array<number> | null} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {number | null} [bycatchPercentage] 
         * @param {boolean | null} [majoritySpeciesGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1HaulHaulsMatrix(activeFilter: ActiveHaulsFilter, months?: Array<number> | null, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, bycatchPercentage?: number | null, majoritySpeciesGroup?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HaulsMatrix>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1HaulHaulsMatrix(activeFilter, months, catchLocations, gearGroupIds, speciesGroupIds, vesselLengthGroups, fiskeridirVesselIds, bycatchPercentage, majoritySpeciesGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HaulApi.routesV1HaulHaulsMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HaulApi - factory interface
 * @export
 */
export const HaulApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HaulApiFp(configuration)
    return {
        /**
         * Returns all hauls matching the provided parameters.
         * @param {HaulApiRoutesV1HaulHaulsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1HaulHauls(requestParameters: HaulApiRoutesV1HaulHaulsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Haul>> {
            return localVarFp.routesV1HaulHauls(requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.sorting, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns an aggregated matrix view of haul living weights.
         * @param {HaulApiRoutesV1HaulHaulsMatrixRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1HaulHaulsMatrix(requestParameters: HaulApiRoutesV1HaulHaulsMatrixRequest, options?: RawAxiosRequestConfig): AxiosPromise<HaulsMatrix> {
            return localVarFp.routesV1HaulHaulsMatrix(requestParameters.activeFilter, requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.bycatchPercentage, requestParameters.majoritySpeciesGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1HaulHauls operation in HaulApi.
 * @export
 * @interface HaulApiRoutesV1HaulHaulsRequest
 */
export interface HaulApiRoutesV1HaulHaulsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof HaulApiRoutesV1HaulHauls
     */
    readonly months?: Array<string> | null

    /**
     * 
     * @type {Array<string>}
     * @memberof HaulApiRoutesV1HaulHauls
     */
    readonly catchLocations?: Array<string> | null

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof HaulApiRoutesV1HaulHauls
     */
    readonly gearGroupIds?: Array<GearGroup> | null

    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof HaulApiRoutesV1HaulHauls
     */
    readonly speciesGroupIds?: Array<SpeciesGroup> | null

    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof HaulApiRoutesV1HaulHauls
     */
    readonly vesselLengthGroups?: Array<VesselLengthGroup> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof HaulApiRoutesV1HaulHauls
     */
    readonly fiskeridirVesselIds?: Array<number> | null

    /**
     * 
     * @type {HaulsSorting}
     * @memberof HaulApiRoutesV1HaulHauls
     */
    readonly sorting?: HaulsSorting | null

    /**
     * 
     * @type {Ordering}
     * @memberof HaulApiRoutesV1HaulHauls
     */
    readonly ordering?: Ordering | null
}

/**
 * Request parameters for routesV1HaulHaulsMatrix operation in HaulApi.
 * @export
 * @interface HaulApiRoutesV1HaulHaulsMatrixRequest
 */
export interface HaulApiRoutesV1HaulHaulsMatrixRequest {
    /**
     * 
     * @type {ActiveHaulsFilter}
     * @memberof HaulApiRoutesV1HaulHaulsMatrix
     */
    readonly activeFilter: ActiveHaulsFilter

    /**
     * 
     * @type {Array<number>}
     * @memberof HaulApiRoutesV1HaulHaulsMatrix
     */
    readonly months?: Array<number> | null

    /**
     * 
     * @type {Array<string>}
     * @memberof HaulApiRoutesV1HaulHaulsMatrix
     */
    readonly catchLocations?: Array<string> | null

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof HaulApiRoutesV1HaulHaulsMatrix
     */
    readonly gearGroupIds?: Array<GearGroup> | null

    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof HaulApiRoutesV1HaulHaulsMatrix
     */
    readonly speciesGroupIds?: Array<SpeciesGroup> | null

    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof HaulApiRoutesV1HaulHaulsMatrix
     */
    readonly vesselLengthGroups?: Array<VesselLengthGroup> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof HaulApiRoutesV1HaulHaulsMatrix
     */
    readonly fiskeridirVesselIds?: Array<number> | null

    /**
     * 
     * @type {number}
     * @memberof HaulApiRoutesV1HaulHaulsMatrix
     */
    readonly bycatchPercentage?: number | null

    /**
     * 
     * @type {boolean}
     * @memberof HaulApiRoutesV1HaulHaulsMatrix
     */
    readonly majoritySpeciesGroup?: boolean | null
}

/**
 * HaulApi - object-oriented interface
 * @export
 * @class HaulApi
 * @extends {BaseAPI}
 */
export class HaulApi extends BaseAPI {
    /**
     * Returns all hauls matching the provided parameters.
     * @param {HaulApiRoutesV1HaulHaulsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HaulApi
     */
    public routesV1HaulHauls(requestParameters: HaulApiRoutesV1HaulHaulsRequest = {}, options?: RawAxiosRequestConfig) {
        return HaulApiFp(this.configuration).routesV1HaulHauls(requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.sorting, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns an aggregated matrix view of haul living weights.
     * @param {HaulApiRoutesV1HaulHaulsMatrixRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HaulApi
     */
    public routesV1HaulHaulsMatrix(requestParameters: HaulApiRoutesV1HaulHaulsMatrixRequest, options?: RawAxiosRequestConfig) {
        return HaulApiFp(this.configuration).routesV1HaulHaulsMatrix(requestParameters.activeFilter, requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.bycatchPercentage, requestParameters.majoritySpeciesGroup, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LandingApi - axios parameter creator
 * @export
 */
export const LandingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns an aggregated matrix view of landing living weights.
         * @param {ActiveLandingFilter} activeFilter 
         * @param {Array<number> | null} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1LandingLandingMatrix: async (activeFilter: ActiveLandingFilter, months?: Array<number> | null, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeFilter' is not null or undefined
            assertParamExists('routesV1LandingLandingMatrix', 'activeFilter', activeFilter)
            const localVarPath = `/v1.0/landing_matrix/{active_filter}`
                .replace(`{${"active_filter"}}`, encodeURIComponent(String(activeFilter)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (months) {
                localVarQueryParameter['months[]'] = months;
            }

            if (catchLocations) {
                localVarQueryParameter['catchLocations[]'] = catchLocations;
            }

            if (gearGroupIds) {
                localVarQueryParameter['gearGroupIds[]'] = gearGroupIds;
            }

            if (speciesGroupIds) {
                localVarQueryParameter['speciesGroupIds[]'] = speciesGroupIds;
            }

            if (vesselLengthGroups) {
                localVarQueryParameter['vesselLengthGroups[]'] = vesselLengthGroups;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all landings matching the provided parameters.
         * @param {Array<string> | null} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {LandingsSorting | null} [sorting] 
         * @param {Ordering | null} [ordering] 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1LandingLandings: async (months?: Array<string> | null, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, sorting?: LandingsSorting | null, ordering?: Ordering | null, limit?: number | null, offset?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/landings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (months) {
                localVarQueryParameter['months[]'] = months;
            }

            if (catchLocations) {
                localVarQueryParameter['catchLocations[]'] = catchLocations;
            }

            if (gearGroupIds) {
                localVarQueryParameter['gearGroupIds[]'] = gearGroupIds;
            }

            if (speciesGroupIds) {
                localVarQueryParameter['speciesGroupIds[]'] = speciesGroupIds;
            }

            if (vesselLengthGroups) {
                localVarQueryParameter['vesselLengthGroups[]'] = vesselLengthGroups;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LandingApi - functional programming interface
 * @export
 */
export const LandingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LandingApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns an aggregated matrix view of landing living weights.
         * @param {ActiveLandingFilter} activeFilter 
         * @param {Array<number> | null} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1LandingLandingMatrix(activeFilter: ActiveLandingFilter, months?: Array<number> | null, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LandingMatrix>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1LandingLandingMatrix(activeFilter, months, catchLocations, gearGroupIds, speciesGroupIds, vesselLengthGroups, fiskeridirVesselIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LandingApi.routesV1LandingLandingMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all landings matching the provided parameters.
         * @param {Array<string> | null} [months] 
         * @param {Array<string> | null} [catchLocations] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {LandingsSorting | null} [sorting] 
         * @param {Ordering | null} [ordering] 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1LandingLandings(months?: Array<string> | null, catchLocations?: Array<string> | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, sorting?: LandingsSorting | null, ordering?: Ordering | null, limit?: number | null, offset?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Landing>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1LandingLandings(months, catchLocations, gearGroupIds, speciesGroupIds, vesselLengthGroups, fiskeridirVesselIds, sorting, ordering, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LandingApi.routesV1LandingLandings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LandingApi - factory interface
 * @export
 */
export const LandingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LandingApiFp(configuration)
    return {
        /**
         * Returns an aggregated matrix view of landing living weights.
         * @param {LandingApiRoutesV1LandingLandingMatrixRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1LandingLandingMatrix(requestParameters: LandingApiRoutesV1LandingLandingMatrixRequest, options?: RawAxiosRequestConfig): AxiosPromise<LandingMatrix> {
            return localVarFp.routesV1LandingLandingMatrix(requestParameters.activeFilter, requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all landings matching the provided parameters.
         * @param {LandingApiRoutesV1LandingLandingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1LandingLandings(requestParameters: LandingApiRoutesV1LandingLandingsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Landing>> {
            return localVarFp.routesV1LandingLandings(requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.sorting, requestParameters.ordering, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1LandingLandingMatrix operation in LandingApi.
 * @export
 * @interface LandingApiRoutesV1LandingLandingMatrixRequest
 */
export interface LandingApiRoutesV1LandingLandingMatrixRequest {
    /**
     * 
     * @type {ActiveLandingFilter}
     * @memberof LandingApiRoutesV1LandingLandingMatrix
     */
    readonly activeFilter: ActiveLandingFilter

    /**
     * 
     * @type {Array<number>}
     * @memberof LandingApiRoutesV1LandingLandingMatrix
     */
    readonly months?: Array<number> | null

    /**
     * 
     * @type {Array<string>}
     * @memberof LandingApiRoutesV1LandingLandingMatrix
     */
    readonly catchLocations?: Array<string> | null

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof LandingApiRoutesV1LandingLandingMatrix
     */
    readonly gearGroupIds?: Array<GearGroup> | null

    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof LandingApiRoutesV1LandingLandingMatrix
     */
    readonly speciesGroupIds?: Array<SpeciesGroup> | null

    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof LandingApiRoutesV1LandingLandingMatrix
     */
    readonly vesselLengthGroups?: Array<VesselLengthGroup> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof LandingApiRoutesV1LandingLandingMatrix
     */
    readonly fiskeridirVesselIds?: Array<number> | null
}

/**
 * Request parameters for routesV1LandingLandings operation in LandingApi.
 * @export
 * @interface LandingApiRoutesV1LandingLandingsRequest
 */
export interface LandingApiRoutesV1LandingLandingsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof LandingApiRoutesV1LandingLandings
     */
    readonly months?: Array<string> | null

    /**
     * 
     * @type {Array<string>}
     * @memberof LandingApiRoutesV1LandingLandings
     */
    readonly catchLocations?: Array<string> | null

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof LandingApiRoutesV1LandingLandings
     */
    readonly gearGroupIds?: Array<GearGroup> | null

    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof LandingApiRoutesV1LandingLandings
     */
    readonly speciesGroupIds?: Array<SpeciesGroup> | null

    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof LandingApiRoutesV1LandingLandings
     */
    readonly vesselLengthGroups?: Array<VesselLengthGroup> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof LandingApiRoutesV1LandingLandings
     */
    readonly fiskeridirVesselIds?: Array<number> | null

    /**
     * 
     * @type {LandingsSorting}
     * @memberof LandingApiRoutesV1LandingLandings
     */
    readonly sorting?: LandingsSorting | null

    /**
     * 
     * @type {Ordering}
     * @memberof LandingApiRoutesV1LandingLandings
     */
    readonly ordering?: Ordering | null

    /**
     * 
     * @type {number}
     * @memberof LandingApiRoutesV1LandingLandings
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof LandingApiRoutesV1LandingLandings
     */
    readonly offset?: number | null
}

/**
 * LandingApi - object-oriented interface
 * @export
 * @class LandingApi
 * @extends {BaseAPI}
 */
export class LandingApi extends BaseAPI {
    /**
     * Returns an aggregated matrix view of landing living weights.
     * @param {LandingApiRoutesV1LandingLandingMatrixRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingApi
     */
    public routesV1LandingLandingMatrix(requestParameters: LandingApiRoutesV1LandingLandingMatrixRequest, options?: RawAxiosRequestConfig) {
        return LandingApiFp(this.configuration).routesV1LandingLandingMatrix(requestParameters.activeFilter, requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all landings matching the provided parameters.
     * @param {LandingApiRoutesV1LandingLandingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LandingApi
     */
    public routesV1LandingLandings(requestParameters: LandingApiRoutesV1LandingLandingsRequest = {}, options?: RawAxiosRequestConfig) {
        return LandingApiFp(this.configuration).routesV1LandingLandings(requestParameters.months, requestParameters.catchLocations, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, requestParameters.sorting, requestParameters.ordering, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrgApi - axios parameter creator
 * @export
 */
export const OrgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns organization benchmarks for the given organization id (Breg org id). This will include benchmarks for all vessels associated with the organization.
         * @param {string} bwToken 
         * @param {number} orgId 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1OrgBenchmarks: async (bwToken: string, orgId: number, start?: string | null, end?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1OrgBenchmarks', 'bwToken', bwToken)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('routesV1OrgBenchmarks', 'orgId', orgId)
            const localVarPath = `/v1.0/org/{org_id}/benchmarks`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a fuel consumption estimate for the given date range for all vessels associated with the given org, if no date range is given the last 30 days are returned. This is not based on trips and is the full fuel consumption estimate for the given date range.
         * @param {string} bwToken 
         * @param {number} orgId 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1OrgFuel: async (bwToken: string, orgId: number, startDate?: string | null, endDate?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1OrgFuel', 'bwToken', bwToken)
            // verify required parameter 'orgId' is not null or undefined
            assertParamExists('routesV1OrgFuel', 'orgId', orgId)
            const localVarPath = `/v1.0/org/{org_id}/fuel`
                .replace(`{${"org_id"}}`, encodeURIComponent(String(orgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgApi - functional programming interface
 * @export
 */
export const OrgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrgApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns organization benchmarks for the given organization id (Breg org id). This will include benchmarks for all vessels associated with the organization.
         * @param {string} bwToken 
         * @param {number} orgId 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1OrgBenchmarks(bwToken: string, orgId: number, start?: string | null, end?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgBenchmarks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1OrgBenchmarks(bwToken, orgId, start, end, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.routesV1OrgBenchmarks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a fuel consumption estimate for the given date range for all vessels associated with the given org, if no date range is given the last 30 days are returned. This is not based on trips and is the full fuel consumption estimate for the given date range.
         * @param {string} bwToken 
         * @param {number} orgId 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1OrgFuel(bwToken: string, orgId: number, startDate?: string | null, endDate?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FuelEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1OrgFuel(bwToken, orgId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrgApi.routesV1OrgFuel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrgApi - factory interface
 * @export
 */
export const OrgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrgApiFp(configuration)
    return {
        /**
         * Returns organization benchmarks for the given organization id (Breg org id). This will include benchmarks for all vessels associated with the organization.
         * @param {OrgApiRoutesV1OrgBenchmarksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1OrgBenchmarks(requestParameters: OrgApiRoutesV1OrgBenchmarksRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrgBenchmarks> {
            return localVarFp.routesV1OrgBenchmarks(requestParameters.bwToken, requestParameters.orgId, requestParameters.start, requestParameters.end, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a fuel consumption estimate for the given date range for all vessels associated with the given org, if no date range is given the last 30 days are returned. This is not based on trips and is the full fuel consumption estimate for the given date range.
         * @param {OrgApiRoutesV1OrgFuelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1OrgFuel(requestParameters: OrgApiRoutesV1OrgFuelRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FuelEntry>> {
            return localVarFp.routesV1OrgFuel(requestParameters.bwToken, requestParameters.orgId, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1OrgBenchmarks operation in OrgApi.
 * @export
 * @interface OrgApiRoutesV1OrgBenchmarksRequest
 */
export interface OrgApiRoutesV1OrgBenchmarksRequest {
    /**
     * 
     * @type {string}
     * @memberof OrgApiRoutesV1OrgBenchmarks
     */
    readonly bwToken: string

    /**
     * 
     * @type {number}
     * @memberof OrgApiRoutesV1OrgBenchmarks
     */
    readonly orgId: number

    /**
     * 
     * @type {string}
     * @memberof OrgApiRoutesV1OrgBenchmarks
     */
    readonly start?: string | null

    /**
     * 
     * @type {string}
     * @memberof OrgApiRoutesV1OrgBenchmarks
     */
    readonly end?: string | null
}

/**
 * Request parameters for routesV1OrgFuel operation in OrgApi.
 * @export
 * @interface OrgApiRoutesV1OrgFuelRequest
 */
export interface OrgApiRoutesV1OrgFuelRequest {
    /**
     * 
     * @type {string}
     * @memberof OrgApiRoutesV1OrgFuel
     */
    readonly bwToken: string

    /**
     * 
     * @type {number}
     * @memberof OrgApiRoutesV1OrgFuel
     */
    readonly orgId: number

    /**
     * 
     * @type {string}
     * @memberof OrgApiRoutesV1OrgFuel
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof OrgApiRoutesV1OrgFuel
     */
    readonly endDate?: string | null
}

/**
 * OrgApi - object-oriented interface
 * @export
 * @class OrgApi
 * @extends {BaseAPI}
 */
export class OrgApi extends BaseAPI {
    /**
     * Returns organization benchmarks for the given organization id (Breg org id). This will include benchmarks for all vessels associated with the organization.
     * @param {OrgApiRoutesV1OrgBenchmarksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public routesV1OrgBenchmarks(requestParameters: OrgApiRoutesV1OrgBenchmarksRequest, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).routesV1OrgBenchmarks(requestParameters.bwToken, requestParameters.orgId, requestParameters.start, requestParameters.end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a fuel consumption estimate for the given date range for all vessels associated with the given org, if no date range is given the last 30 days are returned. This is not based on trips and is the full fuel consumption estimate for the given date range.
     * @param {OrgApiRoutesV1OrgFuelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public routesV1OrgFuel(requestParameters: OrgApiRoutesV1OrgFuelRequest, options?: RawAxiosRequestConfig) {
        return OrgApiFp(this.configuration).routesV1OrgFuel(requestParameters.bwToken, requestParameters.orgId, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SpeciesApi - axios parameter creator
 * @export
 */
export const SpeciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1SpeciesSpecies: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/species`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1SpeciesSpeciesFao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/species_fao`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1SpeciesSpeciesFiskeridir: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/species_fiskeridir`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean | null} [hasMlModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1SpeciesSpeciesGroups: async (hasMlModels?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/species_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (hasMlModels !== undefined) {
                localVarQueryParameter['hasMlModels'] = hasMlModels;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1SpeciesSpeciesMainGroups: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/species_main_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpeciesApi - functional programming interface
 * @export
 */
export const SpeciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpeciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1SpeciesSpecies(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Species>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1SpeciesSpecies(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpeciesApi.routesV1SpeciesSpecies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1SpeciesSpeciesFao(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeciesFao>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1SpeciesSpeciesFao(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpeciesApi.routesV1SpeciesSpeciesFao']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1SpeciesSpeciesFiskeridir(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeciesFiskeridir>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1SpeciesSpeciesFiskeridir(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpeciesApi.routesV1SpeciesSpeciesFiskeridir']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean | null} [hasMlModels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1SpeciesSpeciesGroups(hasMlModels?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeciesGroupDetailed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1SpeciesSpeciesGroups(hasMlModels, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpeciesApi.routesV1SpeciesSpeciesGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1SpeciesSpeciesMainGroups(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeciesMainGroupDetailed>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1SpeciesSpeciesMainGroups(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SpeciesApi.routesV1SpeciesSpeciesMainGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SpeciesApi - factory interface
 * @export
 */
export const SpeciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpeciesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1SpeciesSpecies(options?: RawAxiosRequestConfig): AxiosPromise<Array<Species>> {
            return localVarFp.routesV1SpeciesSpecies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1SpeciesSpeciesFao(options?: RawAxiosRequestConfig): AxiosPromise<Array<SpeciesFao>> {
            return localVarFp.routesV1SpeciesSpeciesFao(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1SpeciesSpeciesFiskeridir(options?: RawAxiosRequestConfig): AxiosPromise<Array<SpeciesFiskeridir>> {
            return localVarFp.routesV1SpeciesSpeciesFiskeridir(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpeciesApiRoutesV1SpeciesSpeciesGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1SpeciesSpeciesGroups(requestParameters: SpeciesApiRoutesV1SpeciesSpeciesGroupsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<SpeciesGroupDetailed>> {
            return localVarFp.routesV1SpeciesSpeciesGroups(requestParameters.hasMlModels, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1SpeciesSpeciesMainGroups(options?: RawAxiosRequestConfig): AxiosPromise<Array<SpeciesMainGroupDetailed>> {
            return localVarFp.routesV1SpeciesSpeciesMainGroups(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1SpeciesSpeciesGroups operation in SpeciesApi.
 * @export
 * @interface SpeciesApiRoutesV1SpeciesSpeciesGroupsRequest
 */
export interface SpeciesApiRoutesV1SpeciesSpeciesGroupsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SpeciesApiRoutesV1SpeciesSpeciesGroups
     */
    readonly hasMlModels?: boolean | null
}

/**
 * SpeciesApi - object-oriented interface
 * @export
 * @class SpeciesApi
 * @extends {BaseAPI}
 */
export class SpeciesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public routesV1SpeciesSpecies(options?: RawAxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).routesV1SpeciesSpecies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public routesV1SpeciesSpeciesFao(options?: RawAxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).routesV1SpeciesSpeciesFao(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public routesV1SpeciesSpeciesFiskeridir(options?: RawAxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).routesV1SpeciesSpeciesFiskeridir(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpeciesApiRoutesV1SpeciesSpeciesGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public routesV1SpeciesSpeciesGroups(requestParameters: SpeciesApiRoutesV1SpeciesSpeciesGroupsRequest = {}, options?: RawAxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).routesV1SpeciesSpeciesGroups(requestParameters.hasMlModels, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public routesV1SpeciesSpeciesMainGroups(options?: RawAxiosRequestConfig) {
        return SpeciesApiFp(this.configuration).routesV1SpeciesSpeciesMainGroups(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TripApi - axios parameter creator
 * @export
 */
export const TripApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the average trip benchmarks for the given timespan and vessels matching the given parameters.
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<GearGroup> | null} [gearGroups] 
         * @param {VesselLengthGroup | null} [lengthGroup] 
         * @param {Array<number> | null} [vesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripBenchmarksAverage: async (startDate?: string, endDate?: string, gearGroups?: Array<GearGroup> | null, lengthGroup?: VesselLengthGroup | null, vesselIds?: Array<number> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/trip/benchmarks/average`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (gearGroups) {
                localVarQueryParameter['gearGroups[]'] = gearGroups;
            }

            if (lengthGroup !== undefined) {
                localVarQueryParameter['lengthGroup'] = lengthGroup;
            }

            if (vesselIds) {
                localVarQueryParameter['vesselIds[]'] = vesselIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the average EEOI of all vessels matching the given parameters. EEOI is given with the unit: `tonn / (tonn * nautical miles)`
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<GearGroup> | null} [gearGroups] 
         * @param {VesselLengthGroup | null} [lengthGroup] 
         * @param {Array<number> | null} [vesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripBenchmarksAverageEeoi: async (startDate?: string, endDate?: string, gearGroups?: Array<GearGroup> | null, lengthGroup?: VesselLengthGroup | null, vesselIds?: Array<number> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/trip/benchmarks/average_eeoi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (gearGroups) {
                localVarQueryParameter['gearGroups[]'] = gearGroups;
            }

            if (lengthGroup !== undefined) {
                localVarQueryParameter['lengthGroup'] = lengthGroup;
            }

            if (vesselIds) {
                localVarQueryParameter['vesselIds[]'] = vesselIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns trip benchmarks for the vessel associated with the authenticated user.
         * @param {string} bwToken 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Ordering | null} [ordering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripBenchmarksBenchmarks: async (bwToken: string, startDate?: string | null, endDate?: string | null, ordering?: Ordering | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1TripBenchmarksBenchmarks', 'bwToken', bwToken)
            const localVarPath = `/v1.0/trip/benchmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the EEOI of the logged in user for the given period. EEOI is given with the unit: `tonn / (tonn * nautical miles)`
         * @param {string} bwToken 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripBenchmarksEeoi: async (bwToken: string, startDate?: string | null, endDate?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1TripBenchmarksEeoi', 'bwToken', bwToken)
            const localVarPath = `/v1.0/trip/benchmarks/eeoi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the current trip of the given vessel, which is determined by the vessel\'s last reported DEP message. All vessels below 15m will not have a current trip as they do not report DEP messages.
         * @param {number} fiskeridirVesselId 
         * @param {string} [bwToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripCurrentTrip: async (fiskeridirVesselId: number, bwToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fiskeridirVesselId' is not null or undefined
            assertParamExists('routesV1TripCurrentTrip', 'fiskeridirVesselId', fiskeridirVesselId)
            const localVarPath = `/v1.0/trips/current/{fiskeridir_vessel_id}`
                .replace(`{${"fiskeridir_vessel_id"}}`, encodeURIComponent(String(fiskeridirVesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the trip associated with the given haul.
         * @param {number} haulId 
         * @param {string} [bwToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripTripOfHaulTripOfHaul: async (haulId: number, bwToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'haulId' is not null or undefined
            assertParamExists('routesV1TripTripOfHaulTripOfHaul', 'haulId', haulId)
            const localVarPath = `/v1.0/trip_of_haul/{haul_id}`
                .replace(`{${"haul_id"}}`, encodeURIComponent(String(haulId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the trip associated with the given landing.
         * @param {string} landingId 
         * @param {string} [bwToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripTripOfLanding: async (landingId: string, bwToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'landingId' is not null or undefined
            assertParamExists('routesV1TripTripOfLanding', 'landingId', landingId)
            const localVarPath = `/v1.0/trip_of_landing/{landing_id}`
                .replace(`{${"landing_id"}}`, encodeURIComponent(String(landingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all trips matching the provided parameters. All vessels below 15m have significantly reduced trip data quality as they do not report ERS POR and DEP messages.
         * @param {string} [bwToken] 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {Ordering | null} [ordering] 
         * @param {Array<string> | null} [deliveryPoints] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {number | null} [minWeight] 
         * @param {number | null} [maxWeight] 
         * @param {TripSorting | null} [sorting] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripTrips: async (bwToken?: string, limit?: number | null, offset?: number | null, ordering?: Ordering | null, deliveryPoints?: Array<string> | null, startDate?: string | null, endDate?: string | null, minWeight?: number | null, maxWeight?: number | null, sorting?: TripSorting | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/trips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (deliveryPoints) {
                localVarQueryParameter['deliveryPoints[]'] = deliveryPoints;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (minWeight !== undefined) {
                localVarQueryParameter['minWeight'] = minWeight;
            }

            if (maxWeight !== undefined) {
                localVarQueryParameter['maxWeight'] = maxWeight;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }

            if (gearGroupIds) {
                localVarQueryParameter['gearGroupIds[]'] = gearGroupIds;
            }

            if (speciesGroupIds) {
                localVarQueryParameter['speciesGroupIds[]'] = speciesGroupIds;
            }

            if (vesselLengthGroups) {
                localVarQueryParameter['vesselLengthGroups[]'] = vesselLengthGroups;
            }

            if (fiskeridirVesselIds) {
                localVarQueryParameter['fiskeridirVesselIds[]'] = fiskeridirVesselIds;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TripApi - functional programming interface
 * @export
 */
export const TripApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TripApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the average trip benchmarks for the given timespan and vessels matching the given parameters.
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<GearGroup> | null} [gearGroups] 
         * @param {VesselLengthGroup | null} [lengthGroup] 
         * @param {Array<number> | null} [vesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1TripBenchmarksAverage(startDate?: string, endDate?: string, gearGroups?: Array<GearGroup> | null, lengthGroup?: VesselLengthGroup | null, vesselIds?: Array<number> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AverageTripBenchmarks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1TripBenchmarksAverage(startDate, endDate, gearGroups, lengthGroup, vesselIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TripApi.routesV1TripBenchmarksAverage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the average EEOI of all vessels matching the given parameters. EEOI is given with the unit: `tonn / (tonn * nautical miles)`
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {Array<GearGroup> | null} [gearGroups] 
         * @param {VesselLengthGroup | null} [lengthGroup] 
         * @param {Array<number> | null} [vesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1TripBenchmarksAverageEeoi(startDate?: string, endDate?: string, gearGroups?: Array<GearGroup> | null, lengthGroup?: VesselLengthGroup | null, vesselIds?: Array<number> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1TripBenchmarksAverageEeoi(startDate, endDate, gearGroups, lengthGroup, vesselIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TripApi.routesV1TripBenchmarksAverageEeoi']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns trip benchmarks for the vessel associated with the authenticated user.
         * @param {string} bwToken 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Ordering | null} [ordering] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1TripBenchmarksBenchmarks(bwToken: string, startDate?: string | null, endDate?: string | null, ordering?: Ordering | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TripBenchmarks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1TripBenchmarksBenchmarks(bwToken, startDate, endDate, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TripApi.routesV1TripBenchmarksBenchmarks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the EEOI of the logged in user for the given period. EEOI is given with the unit: `tonn / (tonn * nautical miles)`
         * @param {string} bwToken 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1TripBenchmarksEeoi(bwToken: string, startDate?: string | null, endDate?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1TripBenchmarksEeoi(bwToken, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TripApi.routesV1TripBenchmarksEeoi']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the current trip of the given vessel, which is determined by the vessel\'s last reported DEP message. All vessels below 15m will not have a current trip as they do not report DEP messages.
         * @param {number} fiskeridirVesselId 
         * @param {string} [bwToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1TripCurrentTrip(fiskeridirVesselId: number, bwToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentTrip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1TripCurrentTrip(fiskeridirVesselId, bwToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TripApi.routesV1TripCurrentTrip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the trip associated with the given haul.
         * @param {number} haulId 
         * @param {string} [bwToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1TripTripOfHaulTripOfHaul(haulId: number, bwToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1TripTripOfHaulTripOfHaul(haulId, bwToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TripApi.routesV1TripTripOfHaulTripOfHaul']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the trip associated with the given landing.
         * @param {string} landingId 
         * @param {string} [bwToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1TripTripOfLanding(landingId: string, bwToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1TripTripOfLanding(landingId, bwToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TripApi.routesV1TripTripOfLanding']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all trips matching the provided parameters. All vessels below 15m have significantly reduced trip data quality as they do not report ERS POR and DEP messages.
         * @param {string} [bwToken] 
         * @param {number | null} [limit] 
         * @param {number | null} [offset] 
         * @param {Ordering | null} [ordering] 
         * @param {Array<string> | null} [deliveryPoints] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {number | null} [minWeight] 
         * @param {number | null} [maxWeight] 
         * @param {TripSorting | null} [sorting] 
         * @param {Array<GearGroup> | null} [gearGroupIds] 
         * @param {Array<SpeciesGroup> | null} [speciesGroupIds] 
         * @param {Array<VesselLengthGroup> | null} [vesselLengthGroups] 
         * @param {Array<number> | null} [fiskeridirVesselIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1TripTrips(bwToken?: string, limit?: number | null, offset?: number | null, ordering?: Ordering | null, deliveryPoints?: Array<string> | null, startDate?: string | null, endDate?: string | null, minWeight?: number | null, maxWeight?: number | null, sorting?: TripSorting | null, gearGroupIds?: Array<GearGroup> | null, speciesGroupIds?: Array<SpeciesGroup> | null, vesselLengthGroups?: Array<VesselLengthGroup> | null, fiskeridirVesselIds?: Array<number> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1TripTrips(bwToken, limit, offset, ordering, deliveryPoints, startDate, endDate, minWeight, maxWeight, sorting, gearGroupIds, speciesGroupIds, vesselLengthGroups, fiskeridirVesselIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TripApi.routesV1TripTrips']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TripApi - factory interface
 * @export
 */
export const TripApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TripApiFp(configuration)
    return {
        /**
         * Returns the average trip benchmarks for the given timespan and vessels matching the given parameters.
         * @param {TripApiRoutesV1TripBenchmarksAverageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripBenchmarksAverage(requestParameters: TripApiRoutesV1TripBenchmarksAverageRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AverageTripBenchmarks> {
            return localVarFp.routesV1TripBenchmarksAverage(requestParameters.startDate, requestParameters.endDate, requestParameters.gearGroups, requestParameters.lengthGroup, requestParameters.vesselIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the average EEOI of all vessels matching the given parameters. EEOI is given with the unit: `tonn / (tonn * nautical miles)`
         * @param {TripApiRoutesV1TripBenchmarksAverageEeoiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripBenchmarksAverageEeoi(requestParameters: TripApiRoutesV1TripBenchmarksAverageEeoiRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.routesV1TripBenchmarksAverageEeoi(requestParameters.startDate, requestParameters.endDate, requestParameters.gearGroups, requestParameters.lengthGroup, requestParameters.vesselIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns trip benchmarks for the vessel associated with the authenticated user.
         * @param {TripApiRoutesV1TripBenchmarksBenchmarksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripBenchmarksBenchmarks(requestParameters: TripApiRoutesV1TripBenchmarksBenchmarksRequest, options?: RawAxiosRequestConfig): AxiosPromise<TripBenchmarks> {
            return localVarFp.routesV1TripBenchmarksBenchmarks(requestParameters.bwToken, requestParameters.startDate, requestParameters.endDate, requestParameters.ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the EEOI of the logged in user for the given period. EEOI is given with the unit: `tonn / (tonn * nautical miles)`
         * @param {TripApiRoutesV1TripBenchmarksEeoiRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripBenchmarksEeoi(requestParameters: TripApiRoutesV1TripBenchmarksEeoiRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.routesV1TripBenchmarksEeoi(requestParameters.bwToken, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the current trip of the given vessel, which is determined by the vessel\'s last reported DEP message. All vessels below 15m will not have a current trip as they do not report DEP messages.
         * @param {TripApiRoutesV1TripCurrentTripRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripCurrentTrip(requestParameters: TripApiRoutesV1TripCurrentTripRequest, options?: RawAxiosRequestConfig): AxiosPromise<CurrentTrip> {
            return localVarFp.routesV1TripCurrentTrip(requestParameters.fiskeridirVesselId, requestParameters.bwToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the trip associated with the given haul.
         * @param {TripApiRoutesV1TripTripOfHaulTripOfHaulRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripTripOfHaulTripOfHaul(requestParameters: TripApiRoutesV1TripTripOfHaulTripOfHaulRequest, options?: RawAxiosRequestConfig): AxiosPromise<Trip> {
            return localVarFp.routesV1TripTripOfHaulTripOfHaul(requestParameters.haulId, requestParameters.bwToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the trip associated with the given landing.
         * @param {TripApiRoutesV1TripTripOfLandingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripTripOfLanding(requestParameters: TripApiRoutesV1TripTripOfLandingRequest, options?: RawAxiosRequestConfig): AxiosPromise<Trip> {
            return localVarFp.routesV1TripTripOfLanding(requestParameters.landingId, requestParameters.bwToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all trips matching the provided parameters. All vessels below 15m have significantly reduced trip data quality as they do not report ERS POR and DEP messages.
         * @param {TripApiRoutesV1TripTripsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1TripTrips(requestParameters: TripApiRoutesV1TripTripsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Trip>> {
            return localVarFp.routesV1TripTrips(requestParameters.bwToken, requestParameters.limit, requestParameters.offset, requestParameters.ordering, requestParameters.deliveryPoints, requestParameters.startDate, requestParameters.endDate, requestParameters.minWeight, requestParameters.maxWeight, requestParameters.sorting, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1TripBenchmarksAverage operation in TripApi.
 * @export
 * @interface TripApiRoutesV1TripBenchmarksAverageRequest
 */
export interface TripApiRoutesV1TripBenchmarksAverageRequest {
    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripBenchmarksAverage
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripBenchmarksAverage
     */
    readonly endDate?: string

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof TripApiRoutesV1TripBenchmarksAverage
     */
    readonly gearGroups?: Array<GearGroup> | null

    /**
     * 
     * @type {VesselLengthGroup}
     * @memberof TripApiRoutesV1TripBenchmarksAverage
     */
    readonly lengthGroup?: VesselLengthGroup | null

    /**
     * 
     * @type {Array<number>}
     * @memberof TripApiRoutesV1TripBenchmarksAverage
     */
    readonly vesselIds?: Array<number> | null
}

/**
 * Request parameters for routesV1TripBenchmarksAverageEeoi operation in TripApi.
 * @export
 * @interface TripApiRoutesV1TripBenchmarksAverageEeoiRequest
 */
export interface TripApiRoutesV1TripBenchmarksAverageEeoiRequest {
    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripBenchmarksAverageEeoi
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripBenchmarksAverageEeoi
     */
    readonly endDate?: string

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof TripApiRoutesV1TripBenchmarksAverageEeoi
     */
    readonly gearGroups?: Array<GearGroup> | null

    /**
     * 
     * @type {VesselLengthGroup}
     * @memberof TripApiRoutesV1TripBenchmarksAverageEeoi
     */
    readonly lengthGroup?: VesselLengthGroup | null

    /**
     * 
     * @type {Array<number>}
     * @memberof TripApiRoutesV1TripBenchmarksAverageEeoi
     */
    readonly vesselIds?: Array<number> | null
}

/**
 * Request parameters for routesV1TripBenchmarksBenchmarks operation in TripApi.
 * @export
 * @interface TripApiRoutesV1TripBenchmarksBenchmarksRequest
 */
export interface TripApiRoutesV1TripBenchmarksBenchmarksRequest {
    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripBenchmarksBenchmarks
     */
    readonly bwToken: string

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripBenchmarksBenchmarks
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripBenchmarksBenchmarks
     */
    readonly endDate?: string | null

    /**
     * 
     * @type {Ordering}
     * @memberof TripApiRoutesV1TripBenchmarksBenchmarks
     */
    readonly ordering?: Ordering | null
}

/**
 * Request parameters for routesV1TripBenchmarksEeoi operation in TripApi.
 * @export
 * @interface TripApiRoutesV1TripBenchmarksEeoiRequest
 */
export interface TripApiRoutesV1TripBenchmarksEeoiRequest {
    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripBenchmarksEeoi
     */
    readonly bwToken: string

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripBenchmarksEeoi
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripBenchmarksEeoi
     */
    readonly endDate?: string | null
}

/**
 * Request parameters for routesV1TripCurrentTrip operation in TripApi.
 * @export
 * @interface TripApiRoutesV1TripCurrentTripRequest
 */
export interface TripApiRoutesV1TripCurrentTripRequest {
    /**
     * 
     * @type {number}
     * @memberof TripApiRoutesV1TripCurrentTrip
     */
    readonly fiskeridirVesselId: number

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripCurrentTrip
     */
    readonly bwToken?: string
}

/**
 * Request parameters for routesV1TripTripOfHaulTripOfHaul operation in TripApi.
 * @export
 * @interface TripApiRoutesV1TripTripOfHaulTripOfHaulRequest
 */
export interface TripApiRoutesV1TripTripOfHaulTripOfHaulRequest {
    /**
     * 
     * @type {number}
     * @memberof TripApiRoutesV1TripTripOfHaulTripOfHaul
     */
    readonly haulId: number

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripTripOfHaulTripOfHaul
     */
    readonly bwToken?: string
}

/**
 * Request parameters for routesV1TripTripOfLanding operation in TripApi.
 * @export
 * @interface TripApiRoutesV1TripTripOfLandingRequest
 */
export interface TripApiRoutesV1TripTripOfLandingRequest {
    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripTripOfLanding
     */
    readonly landingId: string

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripTripOfLanding
     */
    readonly bwToken?: string
}

/**
 * Request parameters for routesV1TripTrips operation in TripApi.
 * @export
 * @interface TripApiRoutesV1TripTripsRequest
 */
export interface TripApiRoutesV1TripTripsRequest {
    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly bwToken?: string

    /**
     * 
     * @type {number}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly limit?: number | null

    /**
     * 
     * @type {number}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly offset?: number | null

    /**
     * 
     * @type {Ordering}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly ordering?: Ordering | null

    /**
     * 
     * @type {Array<string>}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly deliveryPoints?: Array<string> | null

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly endDate?: string | null

    /**
     * 
     * @type {number}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly minWeight?: number | null

    /**
     * 
     * @type {number}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly maxWeight?: number | null

    /**
     * 
     * @type {TripSorting}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly sorting?: TripSorting | null

    /**
     * 
     * @type {Array<GearGroup>}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly gearGroupIds?: Array<GearGroup> | null

    /**
     * 
     * @type {Array<SpeciesGroup>}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly speciesGroupIds?: Array<SpeciesGroup> | null

    /**
     * 
     * @type {Array<VesselLengthGroup>}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly vesselLengthGroups?: Array<VesselLengthGroup> | null

    /**
     * 
     * @type {Array<number>}
     * @memberof TripApiRoutesV1TripTrips
     */
    readonly fiskeridirVesselIds?: Array<number> | null
}

/**
 * TripApi - object-oriented interface
 * @export
 * @class TripApi
 * @extends {BaseAPI}
 */
export class TripApi extends BaseAPI {
    /**
     * Returns the average trip benchmarks for the given timespan and vessels matching the given parameters.
     * @param {TripApiRoutesV1TripBenchmarksAverageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public routesV1TripBenchmarksAverage(requestParameters: TripApiRoutesV1TripBenchmarksAverageRequest = {}, options?: RawAxiosRequestConfig) {
        return TripApiFp(this.configuration).routesV1TripBenchmarksAverage(requestParameters.startDate, requestParameters.endDate, requestParameters.gearGroups, requestParameters.lengthGroup, requestParameters.vesselIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the average EEOI of all vessels matching the given parameters. EEOI is given with the unit: `tonn / (tonn * nautical miles)`
     * @param {TripApiRoutesV1TripBenchmarksAverageEeoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public routesV1TripBenchmarksAverageEeoi(requestParameters: TripApiRoutesV1TripBenchmarksAverageEeoiRequest = {}, options?: RawAxiosRequestConfig) {
        return TripApiFp(this.configuration).routesV1TripBenchmarksAverageEeoi(requestParameters.startDate, requestParameters.endDate, requestParameters.gearGroups, requestParameters.lengthGroup, requestParameters.vesselIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns trip benchmarks for the vessel associated with the authenticated user.
     * @param {TripApiRoutesV1TripBenchmarksBenchmarksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public routesV1TripBenchmarksBenchmarks(requestParameters: TripApiRoutesV1TripBenchmarksBenchmarksRequest, options?: RawAxiosRequestConfig) {
        return TripApiFp(this.configuration).routesV1TripBenchmarksBenchmarks(requestParameters.bwToken, requestParameters.startDate, requestParameters.endDate, requestParameters.ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the EEOI of the logged in user for the given period. EEOI is given with the unit: `tonn / (tonn * nautical miles)`
     * @param {TripApiRoutesV1TripBenchmarksEeoiRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public routesV1TripBenchmarksEeoi(requestParameters: TripApiRoutesV1TripBenchmarksEeoiRequest, options?: RawAxiosRequestConfig) {
        return TripApiFp(this.configuration).routesV1TripBenchmarksEeoi(requestParameters.bwToken, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the current trip of the given vessel, which is determined by the vessel\'s last reported DEP message. All vessels below 15m will not have a current trip as they do not report DEP messages.
     * @param {TripApiRoutesV1TripCurrentTripRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public routesV1TripCurrentTrip(requestParameters: TripApiRoutesV1TripCurrentTripRequest, options?: RawAxiosRequestConfig) {
        return TripApiFp(this.configuration).routesV1TripCurrentTrip(requestParameters.fiskeridirVesselId, requestParameters.bwToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the trip associated with the given haul.
     * @param {TripApiRoutesV1TripTripOfHaulTripOfHaulRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public routesV1TripTripOfHaulTripOfHaul(requestParameters: TripApiRoutesV1TripTripOfHaulTripOfHaulRequest, options?: RawAxiosRequestConfig) {
        return TripApiFp(this.configuration).routesV1TripTripOfHaulTripOfHaul(requestParameters.haulId, requestParameters.bwToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the trip associated with the given landing.
     * @param {TripApiRoutesV1TripTripOfLandingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public routesV1TripTripOfLanding(requestParameters: TripApiRoutesV1TripTripOfLandingRequest, options?: RawAxiosRequestConfig) {
        return TripApiFp(this.configuration).routesV1TripTripOfLanding(requestParameters.landingId, requestParameters.bwToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all trips matching the provided parameters. All vessels below 15m have significantly reduced trip data quality as they do not report ERS POR and DEP messages.
     * @param {TripApiRoutesV1TripTripsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripApi
     */
    public routesV1TripTrips(requestParameters: TripApiRoutesV1TripTripsRequest = {}, options?: RawAxiosRequestConfig) {
        return TripApiFp(this.configuration).routesV1TripTrips(requestParameters.bwToken, requestParameters.limit, requestParameters.offset, requestParameters.ordering, requestParameters.deliveryPoints, requestParameters.startDate, requestParameters.endDate, requestParameters.minWeight, requestParameters.maxWeight, requestParameters.sorting, requestParameters.gearGroupIds, requestParameters.speciesGroupIds, requestParameters.vesselLengthGroups, requestParameters.fiskeridirVesselIds, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} bwToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1UserGetUser: async (bwToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1UserGetUser', 'bwToken', bwToken)
            const localVarPath = `/v1.0/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bwToken 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1UserUpdateUser: async (bwToken: string, user: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1UserUpdateUser', 'bwToken', bwToken)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('routesV1UserUpdateUser', 'user', user)
            const localVarPath = `/v1.0/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} bwToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1UserGetUser(bwToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1UserGetUser(bwToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.routesV1UserGetUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} bwToken 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1UserUpdateUser(bwToken: string, user: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1UserUpdateUser(bwToken, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.routesV1UserUpdateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {UserApiRoutesV1UserGetUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1UserGetUser(requestParameters: UserApiRoutesV1UserGetUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.routesV1UserGetUser(requestParameters.bwToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserApiRoutesV1UserUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1UserUpdateUser(requestParameters: UserApiRoutesV1UserUpdateUserRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.routesV1UserUpdateUser(requestParameters.bwToken, requestParameters.user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1UserGetUser operation in UserApi.
 * @export
 * @interface UserApiRoutesV1UserGetUserRequest
 */
export interface UserApiRoutesV1UserGetUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiRoutesV1UserGetUser
     */
    readonly bwToken: string
}

/**
 * Request parameters for routesV1UserUpdateUser operation in UserApi.
 * @export
 * @interface UserApiRoutesV1UserUpdateUserRequest
 */
export interface UserApiRoutesV1UserUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiRoutesV1UserUpdateUser
     */
    readonly bwToken: string

    /**
     * 
     * @type {User}
     * @memberof UserApiRoutesV1UserUpdateUser
     */
    readonly user: User
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {UserApiRoutesV1UserGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public routesV1UserGetUser(requestParameters: UserApiRoutesV1UserGetUserRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).routesV1UserGetUser(requestParameters.bwToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiRoutesV1UserUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public routesV1UserUpdateUser(requestParameters: UserApiRoutesV1UserUpdateUserRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).routesV1UserUpdateUser(requestParameters.bwToken, requestParameters.user, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VesselApi - axios parameter creator
 * @export
 */
export const VesselApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns benchmark data for the vessel associated with the authenticated user.
         * @param {string} bwToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VesselBenchmarksBenchmarks: async (bwToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1VesselBenchmarksBenchmarks', 'bwToken', bwToken)
            const localVarPath = `/v1.0/vessels/benchmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a fuel consumption estimate for the given date range for the vessel associated with the authenticated user, if no date range is given the last 30 days are returned. This is not based on trips and is the full fuel consumption estimate for the given date range
         * @param {string} bwToken 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VesselFuel: async (bwToken: string, startDate?: string | null, endDate?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1VesselFuel', 'bwToken', bwToken)
            const localVarPath = `/v1.0/vessel/fuel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bwToken 
         * @param {string | null} [threshold] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VesselLiveFuel: async (bwToken: string, threshold?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1VesselLiveFuel', 'bwToken', bwToken)
            const localVarPath = `/v1.0/vessel/live_fuel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (threshold !== undefined) {
                localVarQueryParameter['threshold'] = (threshold as any instanceof Date) ?
                    (threshold as any).toISOString() :
                    threshold;
            }

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the vessel with the provided information. Note that all trip benchmarks that rely on some of the provided information will not be updated immediatley upon updating a vessel, trip benchmark updates can be expected within 24 hours.
         * @param {string} bwToken 
         * @param {UpdateVessel} updateVessel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VesselUpdateVessel: async (bwToken: string, updateVessel: UpdateVessel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bwToken' is not null or undefined
            assertParamExists('routesV1VesselUpdateVessel', 'bwToken', bwToken)
            // verify required parameter 'updateVessel' is not null or undefined
            assertParamExists('routesV1VesselUpdateVessel', 'updateVessel', updateVessel)
            const localVarPath = `/v1.0/vessels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (bwToken != null) {
                localVarHeaderParameter['bw-token'] = String(bwToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVessel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all known vessels.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VesselVessels: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/vessels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VesselApi - functional programming interface
 * @export
 */
export const VesselApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VesselApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns benchmark data for the vessel associated with the authenticated user.
         * @param {string} bwToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1VesselBenchmarksBenchmarks(bwToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselBenchmarks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1VesselBenchmarksBenchmarks(bwToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VesselApi.routesV1VesselBenchmarksBenchmarks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a fuel consumption estimate for the given date range for the vessel associated with the authenticated user, if no date range is given the last 30 days are returned. This is not based on trips and is the full fuel consumption estimate for the given date range
         * @param {string} bwToken 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1VesselFuel(bwToken: string, startDate?: string | null, endDate?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1VesselFuel(bwToken, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VesselApi.routesV1VesselFuel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} bwToken 
         * @param {string | null} [threshold] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1VesselLiveFuel(bwToken: string, threshold?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveFuel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1VesselLiveFuel(bwToken, threshold, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VesselApi.routesV1VesselLiveFuel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the vessel with the provided information. Note that all trip benchmarks that rely on some of the provided information will not be updated immediatley upon updating a vessel, trip benchmark updates can be expected within 24 hours.
         * @param {string} bwToken 
         * @param {UpdateVessel} updateVessel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1VesselUpdateVessel(bwToken: string, updateVessel: UpdateVessel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vessel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1VesselUpdateVessel(bwToken, updateVessel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VesselApi.routesV1VesselUpdateVessel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all known vessels.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1VesselVessels(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vessel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1VesselVessels(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VesselApi.routesV1VesselVessels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VesselApi - factory interface
 * @export
 */
export const VesselApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VesselApiFp(configuration)
    return {
        /**
         * Returns benchmark data for the vessel associated with the authenticated user.
         * @param {VesselApiRoutesV1VesselBenchmarksBenchmarksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VesselBenchmarksBenchmarks(requestParameters: VesselApiRoutesV1VesselBenchmarksBenchmarksRequest, options?: RawAxiosRequestConfig): AxiosPromise<VesselBenchmarks> {
            return localVarFp.routesV1VesselBenchmarksBenchmarks(requestParameters.bwToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a fuel consumption estimate for the given date range for the vessel associated with the authenticated user, if no date range is given the last 30 days are returned. This is not based on trips and is the full fuel consumption estimate for the given date range
         * @param {VesselApiRoutesV1VesselFuelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VesselFuel(requestParameters: VesselApiRoutesV1VesselFuelRequest, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.routesV1VesselFuel(requestParameters.bwToken, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VesselApiRoutesV1VesselLiveFuelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VesselLiveFuel(requestParameters: VesselApiRoutesV1VesselLiveFuelRequest, options?: RawAxiosRequestConfig): AxiosPromise<LiveFuel> {
            return localVarFp.routesV1VesselLiveFuel(requestParameters.bwToken, requestParameters.threshold, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the vessel with the provided information. Note that all trip benchmarks that rely on some of the provided information will not be updated immediatley upon updating a vessel, trip benchmark updates can be expected within 24 hours.
         * @param {VesselApiRoutesV1VesselUpdateVesselRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VesselUpdateVessel(requestParameters: VesselApiRoutesV1VesselUpdateVesselRequest, options?: RawAxiosRequestConfig): AxiosPromise<Vessel> {
            return localVarFp.routesV1VesselUpdateVessel(requestParameters.bwToken, requestParameters.updateVessel, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all known vessels.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VesselVessels(options?: RawAxiosRequestConfig): AxiosPromise<Array<Vessel>> {
            return localVarFp.routesV1VesselVessels(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1VesselBenchmarksBenchmarks operation in VesselApi.
 * @export
 * @interface VesselApiRoutesV1VesselBenchmarksBenchmarksRequest
 */
export interface VesselApiRoutesV1VesselBenchmarksBenchmarksRequest {
    /**
     * 
     * @type {string}
     * @memberof VesselApiRoutesV1VesselBenchmarksBenchmarks
     */
    readonly bwToken: string
}

/**
 * Request parameters for routesV1VesselFuel operation in VesselApi.
 * @export
 * @interface VesselApiRoutesV1VesselFuelRequest
 */
export interface VesselApiRoutesV1VesselFuelRequest {
    /**
     * 
     * @type {string}
     * @memberof VesselApiRoutesV1VesselFuel
     */
    readonly bwToken: string

    /**
     * 
     * @type {string}
     * @memberof VesselApiRoutesV1VesselFuel
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof VesselApiRoutesV1VesselFuel
     */
    readonly endDate?: string | null
}

/**
 * Request parameters for routesV1VesselLiveFuel operation in VesselApi.
 * @export
 * @interface VesselApiRoutesV1VesselLiveFuelRequest
 */
export interface VesselApiRoutesV1VesselLiveFuelRequest {
    /**
     * 
     * @type {string}
     * @memberof VesselApiRoutesV1VesselLiveFuel
     */
    readonly bwToken: string

    /**
     * 
     * @type {string}
     * @memberof VesselApiRoutesV1VesselLiveFuel
     */
    readonly threshold?: string | null
}

/**
 * Request parameters for routesV1VesselUpdateVessel operation in VesselApi.
 * @export
 * @interface VesselApiRoutesV1VesselUpdateVesselRequest
 */
export interface VesselApiRoutesV1VesselUpdateVesselRequest {
    /**
     * 
     * @type {string}
     * @memberof VesselApiRoutesV1VesselUpdateVessel
     */
    readonly bwToken: string

    /**
     * 
     * @type {UpdateVessel}
     * @memberof VesselApiRoutesV1VesselUpdateVessel
     */
    readonly updateVessel: UpdateVessel
}

/**
 * VesselApi - object-oriented interface
 * @export
 * @class VesselApi
 * @extends {BaseAPI}
 */
export class VesselApi extends BaseAPI {
    /**
     * Returns benchmark data for the vessel associated with the authenticated user.
     * @param {VesselApiRoutesV1VesselBenchmarksBenchmarksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public routesV1VesselBenchmarksBenchmarks(requestParameters: VesselApiRoutesV1VesselBenchmarksBenchmarksRequest, options?: RawAxiosRequestConfig) {
        return VesselApiFp(this.configuration).routesV1VesselBenchmarksBenchmarks(requestParameters.bwToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a fuel consumption estimate for the given date range for the vessel associated with the authenticated user, if no date range is given the last 30 days are returned. This is not based on trips and is the full fuel consumption estimate for the given date range
     * @param {VesselApiRoutesV1VesselFuelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public routesV1VesselFuel(requestParameters: VesselApiRoutesV1VesselFuelRequest, options?: RawAxiosRequestConfig) {
        return VesselApiFp(this.configuration).routesV1VesselFuel(requestParameters.bwToken, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VesselApiRoutesV1VesselLiveFuelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public routesV1VesselLiveFuel(requestParameters: VesselApiRoutesV1VesselLiveFuelRequest, options?: RawAxiosRequestConfig) {
        return VesselApiFp(this.configuration).routesV1VesselLiveFuel(requestParameters.bwToken, requestParameters.threshold, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the vessel with the provided information. Note that all trip benchmarks that rely on some of the provided information will not be updated immediatley upon updating a vessel, trip benchmark updates can be expected within 24 hours.
     * @param {VesselApiRoutesV1VesselUpdateVesselRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public routesV1VesselUpdateVessel(requestParameters: VesselApiRoutesV1VesselUpdateVesselRequest, options?: RawAxiosRequestConfig) {
        return VesselApiFp(this.configuration).routesV1VesselUpdateVessel(requestParameters.bwToken, requestParameters.updateVessel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all known vessels.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VesselApi
     */
    public routesV1VesselVessels(options?: RawAxiosRequestConfig) {
        return VesselApiFp(this.configuration).routesV1VesselVessels(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VmsApi - axios parameter creator
 * @export
 */
export const VmsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the VMS track for the given vessel mactching the given filter if any. If no time filter is provided the track of the last 24 hours are returned.
         * @param {string} callSign 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VmsVmsPositions: async (callSign: string, start?: string | null, end?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'callSign' is not null or undefined
            assertParamExists('routesV1VmsVmsPositions', 'callSign', callSign)
            const localVarPath = `/v1.0/vms/{call_sign}`
                .replace(`{${"call_sign"}}`, encodeURIComponent(String(callSign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VmsApi - functional programming interface
 * @export
 */
export const VmsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VmsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the VMS track for the given vessel mactching the given filter if any. If no time filter is provided the track of the last 24 hours are returned.
         * @param {string} callSign 
         * @param {string | null} [start] 
         * @param {string | null} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1VmsVmsPositions(callSign: string, start?: string | null, end?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VmsPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1VmsVmsPositions(callSign, start, end, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VmsApi.routesV1VmsVmsPositions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VmsApi - factory interface
 * @export
 */
export const VmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VmsApiFp(configuration)
    return {
        /**
         * Returns the VMS track for the given vessel mactching the given filter if any. If no time filter is provided the track of the last 24 hours are returned.
         * @param {VmsApiRoutesV1VmsVmsPositionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1VmsVmsPositions(requestParameters: VmsApiRoutesV1VmsVmsPositionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<VmsPosition>> {
            return localVarFp.routesV1VmsVmsPositions(requestParameters.callSign, requestParameters.start, requestParameters.end, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1VmsVmsPositions operation in VmsApi.
 * @export
 * @interface VmsApiRoutesV1VmsVmsPositionsRequest
 */
export interface VmsApiRoutesV1VmsVmsPositionsRequest {
    /**
     * 
     * @type {string}
     * @memberof VmsApiRoutesV1VmsVmsPositions
     */
    readonly callSign: string

    /**
     * 
     * @type {string}
     * @memberof VmsApiRoutesV1VmsVmsPositions
     */
    readonly start?: string | null

    /**
     * 
     * @type {string}
     * @memberof VmsApiRoutesV1VmsVmsPositions
     */
    readonly end?: string | null
}

/**
 * VmsApi - object-oriented interface
 * @export
 * @class VmsApi
 * @extends {BaseAPI}
 */
export class VmsApi extends BaseAPI {
    /**
     * Returns the VMS track for the given vessel mactching the given filter if any. If no time filter is provided the track of the last 24 hours are returned.
     * @param {VmsApiRoutesV1VmsVmsPositionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VmsApi
     */
    public routesV1VmsVmsPositions(requestParameters: VmsApiRoutesV1VmsVmsPositionsRequest, options?: RawAxiosRequestConfig) {
        return VmsApiFp(this.configuration).routesV1VmsVmsPositions(requestParameters.callSign, requestParameters.start, requestParameters.end, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WeatherApi - axios parameter creator
 * @export
 */
export const WeatherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<number> | null} [weatherLocationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1WeatherWeather: async (startDate?: string | null, endDate?: string | null, weatherLocationIds?: Array<number> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/weather`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (weatherLocationIds) {
                localVarQueryParameter['weatherLocationIds[]'] = weatherLocationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<number> | null} [weatherLocationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1WeatherWeatherLocations: async (startDate?: string | null, endDate?: string | null, weatherLocationIds?: Array<number> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/weather_locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (weatherLocationIds) {
                localVarQueryParameter['weatherLocationIds[]'] = weatherLocationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeatherApi - functional programming interface
 * @export
 */
export const WeatherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WeatherApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<number> | null} [weatherLocationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1WeatherWeather(startDate?: string | null, endDate?: string | null, weatherLocationIds?: Array<number> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Weather>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1WeatherWeather(startDate, endDate, weatherLocationIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WeatherApi.routesV1WeatherWeather']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<number> | null} [weatherLocationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routesV1WeatherWeatherLocations(startDate?: string | null, endDate?: string | null, weatherLocationIds?: Array<number> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WeatherLocation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routesV1WeatherWeatherLocations(startDate, endDate, weatherLocationIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WeatherApi.routesV1WeatherWeatherLocations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WeatherApi - factory interface
 * @export
 */
export const WeatherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WeatherApiFp(configuration)
    return {
        /**
         * 
         * @param {WeatherApiRoutesV1WeatherWeatherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1WeatherWeather(requestParameters: WeatherApiRoutesV1WeatherWeatherRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Weather>> {
            return localVarFp.routesV1WeatherWeather(requestParameters.startDate, requestParameters.endDate, requestParameters.weatherLocationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WeatherApiRoutesV1WeatherWeatherLocationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routesV1WeatherWeatherLocations(requestParameters: WeatherApiRoutesV1WeatherWeatherLocationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<WeatherLocation>> {
            return localVarFp.routesV1WeatherWeatherLocations(requestParameters.startDate, requestParameters.endDate, requestParameters.weatherLocationIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for routesV1WeatherWeather operation in WeatherApi.
 * @export
 * @interface WeatherApiRoutesV1WeatherWeatherRequest
 */
export interface WeatherApiRoutesV1WeatherWeatherRequest {
    /**
     * 
     * @type {string}
     * @memberof WeatherApiRoutesV1WeatherWeather
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof WeatherApiRoutesV1WeatherWeather
     */
    readonly endDate?: string | null

    /**
     * 
     * @type {Array<number>}
     * @memberof WeatherApiRoutesV1WeatherWeather
     */
    readonly weatherLocationIds?: Array<number> | null
}

/**
 * Request parameters for routesV1WeatherWeatherLocations operation in WeatherApi.
 * @export
 * @interface WeatherApiRoutesV1WeatherWeatherLocationsRequest
 */
export interface WeatherApiRoutesV1WeatherWeatherLocationsRequest {
    /**
     * 
     * @type {string}
     * @memberof WeatherApiRoutesV1WeatherWeatherLocations
     */
    readonly startDate?: string | null

    /**
     * 
     * @type {string}
     * @memberof WeatherApiRoutesV1WeatherWeatherLocations
     */
    readonly endDate?: string | null

    /**
     * 
     * @type {Array<number>}
     * @memberof WeatherApiRoutesV1WeatherWeatherLocations
     */
    readonly weatherLocationIds?: Array<number> | null
}

/**
 * WeatherApi - object-oriented interface
 * @export
 * @class WeatherApi
 * @extends {BaseAPI}
 */
export class WeatherApi extends BaseAPI {
    /**
     * 
     * @param {WeatherApiRoutesV1WeatherWeatherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeatherApi
     */
    public routesV1WeatherWeather(requestParameters: WeatherApiRoutesV1WeatherWeatherRequest = {}, options?: RawAxiosRequestConfig) {
        return WeatherApiFp(this.configuration).routesV1WeatherWeather(requestParameters.startDate, requestParameters.endDate, requestParameters.weatherLocationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WeatherApiRoutesV1WeatherWeatherLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeatherApi
     */
    public routesV1WeatherWeatherLocations(requestParameters: WeatherApiRoutesV1WeatherWeatherLocationsRequest = {}, options?: RawAxiosRequestConfig) {
        return WeatherApiFp(this.configuration).routesV1WeatherWeatherLocations(requestParameters.startDate, requestParameters.endDate, requestParameters.weatherLocationIds, options).then((request) => request(this.axios, this.basePath));
    }
}



